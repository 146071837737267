import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { Menu, MenuLabel, MenuContent, MenuItem, InlineTextButton, IconMenu } from '..';
import classNames from 'classnames';

import css from './AddImages.module.css';

const USE_ARROW = false;
const CONTENT_POSITION = 'left';
const CONTENT_PLACEMENT_OFFSET = 14;

const ControlMenu = props => {
  const {
    className,
    rootClassName,
    contentPlacementOffset,
    setCoverInProgress,
    setCoverError,
    coverPhoto,
    disableCover,
    onRemoveImage,
    onSetCover,
  } = props;
  const classes = classNames(rootClassName || css.menu, className);
  const coverButtonDisabled = setCoverInProgress || setCoverError;

  const coverMessage = coverPhoto ? (
    <FormattedMessage id="AddImages.unsetCover" />
  ) : (
    <FormattedMessage id="AddImages.cover" />
  );
  return (
    <Menu
      className={classes}
      useArrow={USE_ARROW}
      contentPosition={CONTENT_POSITION}
      contentPlacementOffset={contentPlacementOffset}
    >
      <MenuLabel className={css.menuLabel}>
        <IconMenu />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        <MenuItem key="ControlMenu.cover" className={css.menuItem} isHidden={disableCover}>
          <InlineTextButton
            className={css.menuItemButton}
            type="button"
            onClick={onSetCover}
            disabled={coverButtonDisabled}
          >
            {coverMessage}
          </InlineTextButton>
        </MenuItem>
        <MenuItem key="ControlMenu.delete" className={css.menuItem}>
          <InlineTextButton className={css.menuItemButton} type="button" onClick={onRemoveImage}>
            <FormattedMessage id="AddImages.delete" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

ControlMenu.defaultProps = {
  className: null,
  rootClassName: null,
  contentPlacementOffset: CONTENT_PLACEMENT_OFFSET,
  setCoverInProgress: false,
  setCoverError: null,
  coverPhoto: false,
};

const { func, string, bool, number } = PropTypes;

ControlMenu.propTypes = {
  className: string,
  rootClassName: string,
  contentPlacementOffset: number,
  onRemoveImage: func.isRequired,
  onSetCover: func.isRequired,
  setCoverInProgress: bool.isRequired,
  setCoverError: propTypes.error,
  coverPhoto: bool.isRequired,
};

export default ControlMenu;
