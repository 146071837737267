import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CommunityGuidelines.module.css';

const CommunityGuidelines = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>
        This is a draft during the test phase. The actual legal documents to be updated by the best
        launch.
      </p>

      <p>
        At Attratr, our goal is to build a community that each member respects and helps one another
        to the best of their ability, communicates, has a meaningful time, and finds a better way to
        grow business and creativity together. We want Attractr to become a public square that is
        vibrant and safe for a diverse range of creators and their communities.
      </p>

      <p>
        We just started our journey to build Attractr so the platform is still in MVP (minimum
        viable product) phase. Our commitment is to develop and optimize our platform that creators
        love to use. Our priority is for creators to be motivated and have a team behind them to
        collaborate. At the same time, we need each creator to onboard, challenge themselves, and
        work hard to reach their goal. Because you are raising funds on Attractr from members, each
        creator has to be accountable for what they do, manage their Live Room calendar, communicate
        with members, and/or deliver membership perks.
      </p>

      <p>
        Community Guidelines is to help protect Attratr, all members (audiences and creators),
        businesses and our partners, and others who visit or use our website and services. These
        Guidelines are part of our Acceptable Use Policy and our Terms of Service, and any terms
        defined by the Terms of Service (such as capitalized words like “User,” “Site,” etc.) have
        the same meaning here. These Guidelines apply to everyone, and clarify what is and is not
        allowed on Attractr. By using Attractr, you agree to our Terms of Service, including these
        Guidelines. In the spirit of keeping Attractr a safe and welcoming place for all, we ask
        that you help us uphold these Guidelines by letting us know if they are being violated.
      </p>

      <h3>A. Our Rules</h3>

      <p>
        All members are required to abide by these Community Guidelines, including by acknowledging
        that certain conduct is prohibited, and certain content may be restricted or removed from
        Attractr’s Site.
      </p>

      <ol>
        <li>
          ‍<strong>Identity, Age Requirements:</strong> In short, you are who you say you are.
          Please be yourself -- and if you’re an Attractr member subscribing to Creator’s paid
          membership OR if you’re a Creator raising funds by offering membership, you must be at
          least 18 years old. Don’t provide a false identity or submit any incorrect or misleading
          information about yourself when you sign up for an Attractr account. Use your real name
          and your own photographs. Do not make deep fake accounts.
          <br />
          No burners. If you’re mid-suspension or have been banned from Attractr, don’t try to
          circumvent our enforcement processes by making another account. We also ask that you not
          create multiple accounts, unless you have a legitimate reason for doing so (for example,
          if you have a business account and want to create a personal one).
        </li>
        <li>
          <strong>‍No Illegality (including Fraud):</strong> It’s important to understand that our
          rules aren’t the only ones that matter; many of our Guidelines are based on laws.
          Accordingly, you may not promote, encourage, or engage in any illegal conduct on, using,
          or directed at, Attractr. Do not submit requests or create content that violates any law,
          regulation, court order, or other legal process, including attempts at phishing, money
          laundering, theft, and fraud. We also prohibit the depiction, promotion, or trade of
          illegal drugs or other controlled substances on or through the Site. In reviewing
          potentially illegal conduct, Attractr may (in its sole discretion) take into account
          additional context and the severity of the conduct in question. ‍
        </li>
        <li>
          <strong>Be accountable and responsible:</strong> As a Creator who offers paid membership
          and/or free Live Room scheduled, you are responsible to deliver perks you promised to
          members. These members are there to support you and ready to buy a ticket to see you on
          stage. Please be accountable and responsible to your audiences, and always up to date on
          your calendar and perks deliverable. Please find more about cancellation and refund policy
          when member demands for these options and what implications apply to your payouts.
          <br />
          As a member (audience), you are responsible to attend as much as you can for Live Room
          activities, paid and free, as you book. You can reschedule or cancel your Live Room
          scheduled activities when necessary. Please find how to reschedule or cancel Live Room
          activities here.
        </li>
        <li>
          <strong>No Hate or Disparaging Speech:</strong> We created Attractr to provide
          entertainment, joy, personalized journey, and memorable experiences through connection.
          Everyone has the right to converse their thoughts. However, please do not use hateful or
          disparaging speech on Attractr. We do not permit language or content that attacks,
          demeans, dehumanizes, discriminates, or threatens violence. We also prohibit content that
          promotes organizations or people who promote or do illegal things. Our society is already
          complicated as it is. Let us be good citizens and respect one another.
        </li>
        <li>
          <strong>No Violence or Inciting Violence:</strong> Inciting, encouraging, promoting or
          threatening violence, damage, or harm is prohibited on Attractr, as is content depicting
          graphic or gratuitous violence, including animal cruelty. Please don’t threaten to harm a
          person, a group of people, animals, or property. Do not distribute, post, send, or solicit
          content on the Site that glorifies, advocates, or promotes terrorism or violence,
          including in the pursuit of political, religious, ethnic, or ideological objectives.
        </li>
        <li>
          <strong>No Nudity, Sexually Explicit Content, or Sexualization of Minors:</strong>{' '}
          Attractr is not the place to send or solicit pornographic, indecent, obscene,
          objectionable or sexually explicit content. Nudity on Attractr is prohibited, including
          genitalia, fully-nude buttocks, and female nipples. For Creatrs, it’s their responsibility
          to set the right age restriction on their Listing to avoid inappropriate experience to
          under age audiences. Sexual stimulation or masturbation, with or without nudity, is also
          prohibited. The prohibition on nudity and sexual content applies to real, mimicked or
          implied activity, and includes digitally-created or manipulated content.
          <br />
          Sexualizing minors is strictly prohibited. Do not distribute, post, send, or solicit any
          sexually explicit content of minors, or any content that displays or promotes the physical
          abuse, neglect, endangerment, or psychological disparagement or harm of children.
        </li>
        <li>
          <strong>Do Not Violate Intellectual Property or Other Rights:</strong> Put simply: don’t
          use what doesn’t belong to you. When you submit content to Attractr, you are saying that
          you own that content or have the right to submit it (and we can use it). By using
          Attractr, you agree not to violate, infringe, or misappropriate the intellectual property,
          privacy, publicity, moral rights, or other legal rights of anyone else when you use the
          Site. Nobody likes a copycat.
          <br />
          Attractr is a platform for inspiration. However, do not use the Creators Content to obtain
          information, ideas, performances, or any IP that belongs to Creators in order to copy and
          produce a similar show that our Creators are working on.
        </li>
        <li>
          <strong>‍Do Not Violate Privacy Rights:</strong> We prohibit content that violates the
          confidentiality of personal data or personally identifiable information (e.g., contact or
          social security information) of others. See our Privacy Policy for more information on how
          we collect, use, and share personal information. We will remove content brought to our
          attention that depicts or contains personal data or personally-identifiable information of
          other users or third parties.
        </li>
      </ol>
    </div>
  );
};

CommunityGuidelines.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CommunityGuidelines.propTypes = {
  rootClassName: string,
  className: string,
};

export default CommunityGuidelines;
