import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton, IconUpload } from '../../components';

import css from './EditListingContentForm.module.css';

const UploadButton = props => {
  const { show, title, buttonText, disabled, onClick } = props;

  return show ? (
    <div className={css.uploadImages}>
      <h2 className={css.uploadImagesTitle}>{title}</h2>
      <SecondaryButton
        className={css.uploadButton}
        type="button"
        disabled={disabled}
        onClick={onClick}
      >
        <IconUpload className={css.uploadIcon} />
        {buttonText}
      </SecondaryButton>
    </div>
  ) : null;
};

UploadButton.defaultProps = {
  show: false,
  title: null,
  buttonText: null,
  disabled: false,
  onClick: null,
};

const { bool, string, func } = PropTypes;

UploadButton.propTypes = {
  show: bool.isRequired,
  title: string,
  buttonText: string,
  disabled: bool.isRequired,
  onClick: func.isRequired,
};

export default UploadButton;
