import React, { useRef, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { useShakeAnimation } from '../../util/hooks';
import { FormattedMessage } from '../../util/reactIntl';
import { NewsletterForm } from '../../forms';
import classNames from 'classnames';

import MailingIcon from './MailingIcon';
import css from './SectionNewsletter.module.css';

// Extracted function to handle Mailchimp errors
const mailchimpErrorMessage = (intl, error) => {
  if (error.name === 'Member Exists') {
    return intl.formatMessage({ id: 'SectionNewsletter.errorMemberExist' });
  }

  return intl.formatMessage({ id: 'SectionNewsletter.error' });
};

const SectionNewsletter = props => {
  const {
    rootClassName,
    className,
    intl,
    currentUser,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
  } = props;

  const classes = classNames(css.root, rootClassName, className);

  const {
    attributes: {
      profile: { firstName, lastName },
    },
  } = ensureCurrentUser(currentUser);

  const [isSubmitted, setSubmitted] = useState(false);
  const iconContainerRef = useRef(null);
  const { isAnimating } = useShakeAnimation(iconContainerRef);

  // Simplified function to handle form submission
  const handleSubmit = (values, form) => {
    const { email } = values;

    const subscribingUser = {
      email,
      firstName,
      lastName,
    };

    onSubscribeToList({
      listId: process.env.REACT_APP_MAILCHIMP_NEWSLETTER_LIST_ID,
      subscribingUser,
    }).then(() => {
      form.reset();
      setSubmitted(true);
    });
  };

  const newsletterContent = () => {
    if (subscribeToListError) {
      return <p className={css.error}>{mailchimpErrorMessage(intl, subscribeToListError)}</p>;
    } else if (isSubmitted) {
      return (
        <p className={css.submittedText}>
          <FormattedMessage id="SectionNewsletter.submittedText" />
        </p>
      );
    }

    return (
      <NewsletterForm
        className={css.form}
        onSubmit={handleSubmit}
        inProgress={subscribeToListInProgress}
      />
    );
  };

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.title}>
          <FormattedMessage
            id="SectionNewsletter.title"
            values={{
              highlighted: (
                <span className={css.highlightedText}>
                  <FormattedMessage id="SectionNewsletter.highlighted" />
                </span>
              ),
            }}
          />
        </div>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionNewsletter.subTitle" />
        </p>
        {newsletterContent()}
      </div>
      <div className={css.iconContainer}>
        <span
          className={classNames(css.mailingIconContainer, {
            [css.shakeAnimation]: isAnimating,
          })}
          ref={iconContainerRef}
        >
          <MailingIcon className={css.mailingIcon} />
        </span>
      </div>
    </div>
  );
};

SectionNewsletter.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,
};

SectionNewsletter.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,
};

export default SectionNewsletter;
