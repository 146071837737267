import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMusic.module.css';

const IconMusic = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <g clipPath="url(#a)" fill="#4A4A4A">
        <path d="M5.625 10.875c-1.24 0-2.25 1.01-2.25 2.25 0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5h-.75a.75.75 0 0 1-1.5 0c0-.827.673-1.5 1.5-1.5 1.24 0 2.25 1.01 2.25 2.25 0 1.654-1.346 3-3 3a3.754 3.754 0 0 1-3.75-3.75 4.67 4.67 0 0 1 1.99-3.823L7.204 6.44a3.336 3.336 0 0 0 1.422-2.732A3.338 3.338 0 0 0 5.291.375c-.643 0-1.166.523-1.166 1.166V7.68l-1.44 1.008a5.42 5.42 0 0 0-2.31 4.437 4.503 4.503 0 0 0 3.75 4.432v4.193c0 .62-.505 1.125-1.125 1.125s-1.125-.505-1.125-1.125a.376.376 0 0 1 .75 0v.375h.75v-.375c0-.62-.505-1.125-1.125-1.125s-1.125.505-1.125 1.125c0 1.034.841 1.875 1.875 1.875a1.877 1.877 0 0 0 1.875-1.875v-4.125a3.754 3.754 0 0 0 3.75-3.75c0-1.654-1.346-3-3-3Zm-.75-9.334c0-.23.187-.416.416-.416a2.587 2.587 0 0 1 2.584 2.584c0 .843-.412 1.634-1.102 2.117L4.875 7.155V1.54ZM11.625 16.957l3.75.75v-3.014l-4.5-.9v4.582h-.75c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5v-2.918Zm0-2.25 3 .6v1.486l-3-.6v-1.486Zm-.75 5.168a.75.75 0 1 1-.75-.75h.75v.75ZM19.125 7.086v3.789h-.75c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5v-2.21l3 .3v1.16h-.75c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5v-5.59l-4.5-.45Zm0 5.289a.75.75 0 1 1-.75-.75h.75v.75Zm3.75.75a.75.75 0 1 1-.75-.75h.75v.75Zm-3-3.714V7.914l3 .3v1.497l-3-.3ZM16.125 3.375h.75v4.5h.75v-6c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5.673 1.5 1.5 1.5Zm0-2.25a.75.75 0 0 1 .75.75v.75h-.75a.75.75 0 0 1 0-1.5ZM10.875 8.625c.827 0 1.5-.673 1.5-1.5v-6h-.75v4.5h-.75c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5Zm0-2.25h.75v.75a.75.75 0 1 1-.75-.75ZM13.797 22.907l.305.686a13.583 13.583 0 0 0 7.209-7.715l-.705-.257a12.829 12.829 0 0 1-6.809 7.286ZM21.163 6.236A13.468 13.468 0 0 0 18.8 2.378l-.565.493a12.731 12.731 0 0 1 2.23 3.643l.697-.278Z" />
        <path d="m20.075 14.74-.714-.23c-1.142 3.52-4.142 6.298-7.83 7.249l.188.726c3.932-1.014 7.134-3.981 8.356-7.744ZM19.44 6.208a11.557 11.557 0 0 0-.93-1.55l-.61.434c.327.46.619.948.87 1.45l.67-.334ZM12.78 12.98l.693.29c.267-.641.402-1.32.402-2.02a5.233 5.233 0 0 0-.825-2.827l-.632.404c.463.723.707 1.56.707 2.423 0 .6-.116 1.182-.345 1.73ZM15.375 11.25c0 .86-.16 1.696-.473 2.487l.697.276c.349-.88.526-1.809.526-2.763a7.46 7.46 0 0 0-2.896-5.921l-.461.591a6.713 6.713 0 0 1 2.607 5.33Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconMusic.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMusic.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMusic;
