import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton, SecondaryButton } from '../../components';
import classNames from 'classnames';

import css from './BookingPanel.module.css';

const PanelButtons = props => {
  const { onOpenMembershipModal, onOpenDonateModal } = props;

  const donateButtonClasses = classNames(css.panelButton, css.donateButton);
  return (
    <div className={css.panelButtons}>
      <SecondaryButton className={donateButtonClasses} onClick={onOpenDonateModal}>
        <FormattedMessage id="BookingPanel.donate" />
      </SecondaryButton>
      <PrimaryButton className={css.panelButton} onClick={onOpenMembershipModal}>
        <FormattedMessage id="BookingPanel.subscribe" />
      </PrimaryButton>
    </div>
  );
};

export default PanelButtons;
