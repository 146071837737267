import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { func, oneOfType, shape, string, bool, object } from 'prop-types';
import classNames from 'classnames';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import {
  InlineTextButton,
  PrimaryButton,
  ExternalLink,
  IconMagicStick,
  IconTime,
  IconRestriction,
  ExpandableBio,
} from '..';

import css from './ShowPanel.module.css';

const DESCRIPTION_MAX_LENGTH = 200;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }

  return {};
};

const listingInfoItem = (itemIcon, itemName) => {
  return itemName ? (
    <div className={css.listingInfoItem}>
      {itemIcon}
      <span>{itemName}</span>
    </div>
  ) : null;
};

const ShowPanel = props => {
  const {
    rootClassName,
    className,
    intl,
    listing,
    showListingInProgress,
    listingFields,
    isOwnListing,
    onLocationSearch,
    onOpenDescriptionModal,
  } = props;

  if (showListingInProgress) {
    return null;
  }

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed && listingFields.ticketLink;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const showDates = !(listing.attributes.publicData?.projectDates?.[0] === 'tbc');

  const classes = classNames(rootClassName || css.root, className);
  const bookButtonClasses = classNames(css.bookButton, {
    [css.ownBookButton]: isOwnListing,
  });
  return (
    <div className={classes}>
      <div className={css.desktopPanel}>
        <div className={css.desktopHeader}>
          <p className={css.priceText}>
            <FormattedMessage id="ShowPanel.priceText" />
          </p>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice ? formattedPrice : <FormattedMessage id="ShowPanel.noPrice" />}
          </div>
          {listingFields.showDate && showDates ? (
            <p className={css.dates}>{listingFields.showDate}</p>
          ) : null}
        </div>
        <div className={css.desktopContent}>
          {listingFields.status && listingFields.address ? (
            <p className={css.tour}>
              <FormattedMessage
                id="ShowPanel.tour"
                values={{
                  status: listingFields.status,
                  name: (
                    <span className={css.tourName} onClick={onLocationSearch}>
                      {listingFields.address}
                    </span>
                  ),
                }}
              />
            </p>
          ) : null}
          <div className={css.description}>
            <ExpandableBio
              bio={listingFields.description}
              maxLength={DESCRIPTION_MAX_LENGTH}
              onReadMore={onOpenDescriptionModal}
            />
          </div>
          <div className={css.listingInfo}>
            {listingInfoItem(
              <IconMagicStick className={css.listingInfoItemIcon} />,
              listingFields.category
            )}
            {listingInfoItem(
              <IconTime className={css.listingInfoItemIcon} />,
              listingFields.length
            )}
            {listingInfoItem(
              <IconRestriction className={css.listingInfoItemIcon} />,
              listingFields.age
            )}
          </div>
        </div>

        {showBookingTimeForm ? (
          <ExternalLink className={bookButtonClasses} href={listingFields.ticketLink}>
            <FormattedMessage id="ShowPanel.ctaButtonMessage" />
          </ExternalLink>
        ) : isClosed ? (
          <PrimaryButton className={bookButtonClasses} disabled>
            <FormattedMessage id="ShowPanel.closedListingButtonText" />
          </PrimaryButton>
        ) : null}
      </div>

      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>
        {showBookingTimeForm ? (
          <ExternalLink className={css.bookButton} href={listingFields.ticketLink}>
            <FormattedMessage id="ShowPanel.ctaButtonMessage" />
          </ExternalLink>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="ShowPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ShowPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listingFields: null,
  showListingInProgress: false,
  isOwnListing: false,
  onLocationSearch: null,
  onOpenDescriptionModal: null,
};

ShowPanel.propTypes = {
  rootClassName: string,
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  showListingInProgress: bool.isRequired,
  listingFields: object,
  isOwnListing: bool.isRequired,
  onLocationSearch: func.isRequired,
  onOpenDescriptionModal: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ShowPanel);
