/**
 * Scroll to element that matches the id
 *
 * @param {string} elementId
 * @param {number} topOffset
 */
export const scrollToElement = (elementId, topOffset = 0) => {
  const element = document.getElementById(elementId);
  const scrollWithOffset = element.getBoundingClientRect().top + window.pageYOffset + topOffset;

  // No need to check if window is defined, as this function depends
  // on user action (e.g the window will probably be already defined)
  window.scrollTo({ top: scrollWithOffset, behavior: 'smooth' });
};
