import { useEffect, useState, useRef } from 'react';
import { isWindowDefined } from './data';

const SHAKE_ANIMATION_DELAY = 4000;

const windowDefined = isWindowDefined();

export const useIsElementScrolled = elementRef => {
  const [scrolled, setScrolled] = useState(false);
  const didMountRef = useRef(true);

  useEffect(() => {
    const handleElementScrolled = () => {
      // Avoid updating the scrolled state on first mount
      // as the page will be scrolled to the top, thus the
      // scroll position might not be accurate.
      if (didMountRef.current) {
        didMountRef.current = false;
        return;
      }

      if (elementRef) {
        // Element
        const elementAPI = elementRef?.current;
        const elementOffsetTop = elementAPI.offsetTop;
        const elementHeight = elementAPI.clientHeight;

        // Scroll
        const scrollPosition = windowDefined ? window.scrollY : 0;
        const elementPosition = elementOffsetTop + elementHeight;

        if (scrollPosition > elementPosition) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      }
    };

    document.addEventListener('scroll', handleElementScrolled);

    return () => {
      document.removeEventListener('scroll', handleElementScrolled);
    };
  }, []);

  return scrolled;
};

export const useShakeAnimation = (containerRef, delay = SHAKE_ANIMATION_DELAY) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    function restartAnimation() {
      setIsAnimating(true);
      void container.offsetWidth;
    }

    function handleAnimationEnd() {
      setIsAnimating(false);
      setTimeout(restartAnimation, delay);
    }

    container.addEventListener('animationend', handleAnimationEnd);
    restartAnimation();

    return () => {
      container.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [delay]);

  return { isAnimating };
};

export default useShakeAnimation;
