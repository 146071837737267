import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import classNames from 'classnames';

import css from './BetaNewsletterForm.module.css';

// Extracted function to handle Mailchimp errors
const mailchimpErrorMessage = (intl, error) => {
  if (error.name === 'Member Exists') {
    return intl.formatMessage({ id: 'SectionHero.errorMemberExist' });
  }

  return intl.formatMessage({ id: 'SectionHero.error' });
};

const BetaNewsletterFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        isSubmitted,
        inProgress,
        subscribeToListError,
        intl,
      } = fieldRenderProps;

      const emailLabel = intl.formatMessage({
        id: 'BetaNewsletterForm.email',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'BetaNewsletterForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({ id: 'BetaNewsletterForm.emailRequired' });
      const emailInvalidMessage = intl.formatMessage({
        id: 'BetaNewsletterForm.emailInvalid',
      });

      const emailRequired = validators.required(emailRequiredMessage);
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;
      const submitReady = isSubmitted && !subscribeToListError;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            type="text"
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />

          {subscribeToListError ? (
            <p className={css.error}>{mailchimpErrorMessage(intl, subscribeToListError)}</p>
          ) : null}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            <FormattedMessage id="BetaNewsletterForm.submitButtonText" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

BetaNewsletterFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

BetaNewsletterFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BetaNewsletterForm = compose(injectIntl)(BetaNewsletterFormComponent);

BetaNewsletterForm.displayName = 'BetaNewsletterForm';

export default BetaNewsletterForm;
