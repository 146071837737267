import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const StreamIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_11_265)">
        <path
          d="M18.3143 5.35716L14.2857 7.14287V5.00001C14.2857 4.62113 14.1352 4.25777 13.8673 3.98986C13.5994 3.72195 13.236 3.57144 12.8572 3.57144H2.14287C1.76399 3.57144 1.40062 3.72195 1.13271 3.98986C0.864804 4.25777 0.714294 4.62113 0.714294 5.00001V15C0.714294 15.3789 0.864804 15.7423 1.13271 16.0102C1.40062 16.2781 1.76399 16.4286 2.14287 16.4286H12.8572C13.236 16.4286 13.5994 16.2781 13.8673 16.0102C14.1352 15.7423 14.2857 15.3789 14.2857 15V12.8572L18.3143 14.6429C18.4229 14.6848 18.5401 14.6995 18.6557 14.6858C18.7713 14.672 18.8818 14.6303 18.9776 14.5641C19.0734 14.4979 19.1515 14.4093 19.2052 14.306C19.2589 14.2027 19.2865 14.0879 19.2857 13.9714V6.02858C19.2865 5.91217 19.2589 5.79732 19.2052 5.69403C19.1515 5.59074 19.0734 5.50215 18.9776 5.43595C18.8818 5.36976 18.7713 5.32798 18.6557 5.31425C18.5401 5.30051 18.4229 5.31524 18.3143 5.35716Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_265">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

StreamIcon.defaultProps = {
  className: null,
};

StreamIcon.propTypes = {
  className: string,
};

export default StreamIcon;
