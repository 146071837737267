import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, string, bool, func, oneOfType } from 'prop-types';
import { propTypes, LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton, ModalInMobile } from '../../components';
import omit from 'lodash/omit';
import classNames from 'classnames';

import PanelFilters from './PanelFilters';
import PanelButtons from './PanelButtons';
import PanelTimeslots from './PanelTimeslots';
import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const openBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ ...parse(search), book: true })}`;
  history.push(`${pathname}${searchString}`, state);
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    intl,
    rootClassName,
    className,
    boxRootClassName,
    boxClassName,
    filtersTitleClassName,
    filtersTitle,
    history,
    location,
    currentUserMemberships,
    currentListing,
    isOwnListing,
    isExceptionBlocked,
    availabilityExceptions,
    publicDataAvailabilityExceptions,
    fetchExceptionsInProgress,
    fetchExceptionsError,
    handleFilterAvailabilityExceptions,
    onPaidBooking,
    onBooking,
    onOpenMembershipModal,
    onOpenDonateModal,
    onManageDisableScrolling,
  } = props;
  const isBook = !!parse(location.search).book;
  const classes = classNames(rootClassName || css.root, className);
  const boxClasses = classNames(boxRootClassName || css.bookingPanelBox, boxClassName);

  const showPanelButtons = onOpenMembershipModal && onOpenDonateModal;

  const isClosed = currentListing?.attributes?.state === LISTING_STATE_CLOSED;

  if (isClosed) {
    return (
      <div className={classes}>
        <div className={boxClasses}>
          <p className={css.closedListing}>
            <FormattedMessage id="BookingPanel.listingClosedMessage" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className={boxClasses}>
        {showPanelButtons ? (
          <PanelButtons
            onOpenMembershipModal={onOpenMembershipModal}
            onOpenDonateModal={onOpenDonateModal}
          />
        ) : null}
        <ModalInMobile
          containerClassName={css.modalContainer}
          contentClassName={css.modalContent}
          id="BookingTimeFormInModal"
          isModalOpenOnMobile={isBook}
          onClose={() => closeBookModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <PanelFilters
            intl={intl}
            idPrefix="BookingPanel"
            availabilityExceptions={availabilityExceptions}
            handleFilterAvailabilityExceptions={handleFilterAvailabilityExceptions}
            isExceptionBlocked={isExceptionBlocked}
            filtersTitleClassName={filtersTitleClassName}
            filtersTitle={filtersTitle}
          />
          <PanelTimeslots
            currentUserMemberships={currentUserMemberships}
            isOwnListing={isOwnListing}
            timeslots={availabilityExceptions}
            timeslotsMetadata={publicDataAvailabilityExceptions}
            onPaidBooking={onPaidBooking}
            onBooking={onBooking}
            onOpenMembershipModal={onOpenMembershipModal}
            fetchInProgress={fetchExceptionsInProgress}
            fetchError={fetchExceptionsError}
          />
        </ModalInMobile>
      </div>
      <div className={css.openBookingForm}>
        <h4 className={css.openBookingFormPrice}>
          <FormattedMessage id="BookingPanel.price" />{' '}
          <span className={css.openBookingFormUnit}>
            <FormattedMessage id="BookingPanel.unit" />
          </span>
        </h4>
        <PrimaryButton
          className={css.openBookingButton}
          onClick={() => openBookModal(history, location)}
        >
          <FormattedMessage id="BookingPanel.dates" />
        </PrimaryButton>
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  boxRootClassName: null,
  boxClassName: null,
  filtersTitleClassName: null,
  filtersTitle: null,
  listing: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  boxRootClassName: string,
  boxClassName: string,
  filtersTitleClassName: string,
  filtersTitle: string,
  currentUserMemberships: arrayOf(propTypes.membership),
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.ownListing, propTypes.listing]),

  // availabilityExceptions
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  publicDataAvailabilityExceptions: arrayOf(propTypes.availabilityExceptionMetadata),
  fetchExceptionsInProgress: bool.isRequired,
  fetchExceptionsError: propTypes.error,
  handleFilterAvailabilityExceptions: func.isRequired,
  onPaidBooking: func.isRequired,
  onBooking: func.isRequired,
  isExceptionBlocked: func.isRequired,
  onOpenMembershipModal: func,
  onOpenDonateModal: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
