import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditExperienceContentForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';

import css from './EditExperienceContentPanel.module.css';

const EditExperienceContentPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    listing,
    images,
    videos,
    onImageUpload,
    onUpdateImageOrder,
    onVideosUpload,
    onChange,
    onSubmit,
    onRemoveImage,
    onRemoveVideo,
    onUpdateImages,
    onRedirectToPreviousTab,
    onSetCover,
    setCoverInProgress,
    setCoverError,
    submitButtonText,
    panelUpdated,
    updateInProgress,
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);

  const currentListing = ensureOwnListing(listing);
  const { title } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditExperienceContentPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing} isExperience>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditExperienceContentPanel.createListingTitle" />
  );
  const videosPanelTitle = isPublished ? (
    <FormattedMessage
      id="EditExperienceContentPanel.videosTitle"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing} isExperience>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditExperienceContentPanel.createListingVideosTitle" />
  );

  return (
    <div className={classes}>
      <EditExperienceContentForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        listing={listing}
        images={images}
        videos={videos}
        onImageUpload={onImageUpload}
        onUpdateImages={onUpdateImages}
        onSubmit={values => {
          const { addImages, addVideos, videos, ...updateValues } = values;

          // Pass the update values which currently contains only
          // images, and pass the uploaded videos to the publicData
          const submitValues = {
            ...updateValues,
            publicData: {
              videos: videos.filter(video => !video.invalid),
            },
          };

          onSubmit(submitValues);
        }}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        onRemoveVideo={onRemoveVideo}
        onSetCover={onSetCover}
        setCoverInProgress={setCoverInProgress}
        setCoverError={setCoverError}
        onVideosUpload={onVideosUpload}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        panelTitle={panelTitle}
        videosPanelTitle={videosPanelTitle}
        isPublished={isPublished}
      />
    </div>
  );
};

EditExperienceContentPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
  setCoverInProgress: false,
  setCoverError: null,
};

EditExperienceContentPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onSetCover: func.isRequired,
  onUpdateImages: func.isRequired,
  setCoverInProgress: bool.isRequired,
  setCoverError: propTypes.error,
};

export default EditExperienceContentPanel;
