import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTime.module.css';

const IconTime = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_103_64)">
        <path
          d="M12 23.1428C17.2073 23.1428 21.4286 18.9215 21.4286 13.7143C21.4286 8.50702 17.2073 4.28571 12 4.28571C6.79274 4.28571 2.57143 8.50702 2.57143 13.7143C2.57143 18.9215 6.79274 23.1428 12 23.1428Z"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M0.857147 4.28569C2.06105 2.88727 3.51428 1.72468 5.14286 0.857117"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M23.1429 4.28569C21.939 2.88727 20.4857 1.72468 18.8571 0.857117"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12 8.57141V13.7143H16.2857"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_64">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconTime.defaultProps = {
  className: null,
  rootClassName: null,
};

IconTime.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTime;
