import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLanguage.module.css';

const IconLanguage = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <path
        fill="#4a4a4a"
        fillRule="evenodd"
        d="M10.526 5.947c.025.253.24.443.493.443h1.462a.5.5 0 0 0 .482-.367c.218-.793.378-2.01.187-3.097-.097-.547-.288-1.094-.636-1.538A2.349 2.349 0 0 0 10.92.501c-.065-.002-.133 0-.203.003-.039.003-.078 0-.117-.001L10.576.5H3.59a.5.5 0 0 0-.151.023c-.59.188-1.053.494-1.398.903-.343.405-.55.887-.671 1.4-.238 1.006-.158 2.21-.04 3.369.025.257.053.513.08.766.102.922.199 1.81.187 2.627-.015 1.041-.21 1.83-.696 2.342a.5.5 0 0 0 .036.723H.94c.024.022.05.042.077.06.041.03.098.067.172.109.147.084.362.188.657.288.591.201 1.5.389 2.836.389 1.32 0 2.241-.123 2.842-.25.301-.065.522-.13.673-.182a2.921 2.921 0 0 0 .224-.088l.016-.007.006-.003.002-.001h.002v-.001a.499.499 0 0 0 .157-.122c.775-.904.993-2.071 1.004-3.242.004-.403-.017-.818-.052-1.236-.036-.327-.079-.66-.122-.998l-.003-.027c-.127-1-.262-2.058-.201-2.991.061-.95.33-1.894 1.126-2.545a.625.625 0 1 1 .792.967c-.41.336-.62.867-.67 1.658-.03.46-.005.968.048 1.515ZM3.93 3.314a.625.625 0 1 0 0 1.25h2.307a.625.625 0 1 0 0-1.25H3.93ZM3.603 7c0-.345.28-.625.625-.625h2.307a.625.625 0 1 1 0 1.25H4.228A.625.625 0 0 1 3.603 7Zm.625 2.436a.625.625 0 1 0 0 1.25h2.307a.625.625 0 1 0 0-1.25H4.228Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLanguage.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLanguage.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLanguage;
