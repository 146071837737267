import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, SecondaryButton } from '../../components';

import css from './QuickRepliesForm.module.css';

const QuickRepliesFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        values,
        inProgress,
        submitMessageError,
        isEditReply,
        onDeleteMessage,
      } = fieldRenderProps;

      const name = intl.formatMessage({ id: 'QuickRepliesForm.nameLabel' });
      const namePlaceholderMessage = intl.formatMessage({
        id: 'QuickRepliesForm.namePlaceholder',
      });
      const nameRequiredMessage = intl.formatMessage({
        id: 'QuickRepliesForm.nameRequired',
      });

      const message = intl.formatMessage({ id: 'QuickRepliesForm.messageLabel' });
      const messagePlaceholderMessage = intl.formatMessage({
        id: 'QuickRepliesForm.messagePlaceholder',
      });
      const messageRequiredMessage = intl.formatMessage({
        id: 'QuickRepliesForm.messageRequired',
      });

      const errorMessage = submitMessageError ? (
        <p className={css.error}>
          <FormattedMessage id="QuickRepliesForm.submitFailed" />
        </p>
      ) : null;

      const submitMessage = intl.formatMessage({
        id: 'QuickRepliesForm.submit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            type="text"
            id={formId ? `${formId}.name` : 'name'}
            name="name"
            label={name}
            placeholder={namePlaceholderMessage}
            validate={required(nameRequiredMessage)}
          />

          <FieldTextInput
            type="textarea"
            id={formId ? `${formId}.message` : 'message'}
            name="message"
            label={message}
            placeholder={messagePlaceholderMessage}
            validate={required(messageRequiredMessage)}
          />

          <div className={css.buttons}>
            {isEditReply ? (
              <SecondaryButton
                className={css.deleteButton}
                type="button"
                onClick={() => onDeleteMessage(values.id)}
              >
                <FormattedMessage id="QuickRepliesForm.delete" />
              </SecondaryButton>
            ) : null}

            {errorMessage}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              {submitMessage}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

QuickRepliesFormComponent.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

QuickRepliesFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const QuickRepliesForm = compose(injectIntl)(QuickRepliesFormComponent);
QuickRepliesForm.displayName = 'QuickRepliesForm';

export default QuickRepliesForm;
