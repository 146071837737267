import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>
        This is a draft during the test phase. The actual legal documents to be updated by the best
        launch.
      </p>
      <p>
        This Site Terms of Service Agreement (“Terms”) governs your use of the Attractr® marketplace
        platform offered by Akuntsu Inc., doing business as Attractr (“we”, “us”, or “Attractr”),
        including our website (attractr.live) and services we provide through them (collectively,
        the website and services referred to as our “Site”). “You” refers to you as a user of the
        Site. By using Attractr, you agree to these terms and to the other policies we post,
        including the Privacy Policy and Community Guidelines. Please read them carefully and let us
        know if you have any questions. For information about our data practices, please see our
        Privacy Policy and our Cookie Policy. We collect, use, and share information in accordance
        with those policies.
      </p>
      <p>
        The Attractr Platform offers an online venue that enables users (“Members'') to publish,
        offer, search for, and subscribe to services. Members who publish and offer services are
        “Creators'' and Members who search for, subscribe to, or use services are “Audiences.”
        Creators launch a Show Page and Live Room to start offering or membership (“Listing”), and
        promote Listing uploading short clips (“Video”), and the other information or profiles (all
        creator’s assets collectively, “Creators Content”). You must register an account to access
        and use many features of the Attractr Platform, and must keep your account information
        accurate. As the provider of the Attractr Platform, Attractr does not own, control, offer or
        manage any Listings or Video. Attractr is not a party to the contracts entered into directly
        between Creators and Audiences. Attractr is not acting as an agent in any capacity for any
        Member, except as specified in the Payments Terms of Service (“Payment Terms”).
      </p>
      <h3>1. Our mission</h3>
      <p>
        Our mission is to set the stage for producers, artists, and audiences, to collectively
        change the way live entertainment is created, produced, performed, and experienced by
        building a relation.
      </p>
      <h3>2. Your account</h3>
      <p>
        When you create an account, you must provide us with accurate information, in good faith,
        and you agree to keep your information updated if it changes. To create an account or
        otherwise use Attractr, you must be at least 13 years old and you must also be old enough to
        consent to the processing of your personal data in your country (in some countries we may
        allow your parent or guardian to do so on your behalf). You must be at least 18 years old or
        have your parent’s or legal guardian’s permission to have a creator page on Attractr, or to
        purchase an offering or subscription on Atractr. You must be at least 18 years old to
        subscribe to an Adult/18+ creator or purchase an offering related to such a creator. You are
        responsible for anything that occurs when anyone is signed in to your account, as well as
        the security of the account.
      </p>
      <h3>3. Creator Terms</h3>
      <p>
        To become a creator, launch your Listing to start your offering or membership. When you join
        Attractr, you become part of the Attractr community. You can use creator tools that we
        provide to, among other things, document and showcase your talent live, engage with the
        Attractr community, and upload Video to Audiences through our search. Members are there to
        support you and your projects to grow business and creativity together. On Attractr, you can
        provide your members something exciting that gives them special benefits they want,
        including additional access, personalized experience, members-only content, and engaging
        community experiences. In turn, you will receive loyal support from your Audiences, and
        revenue from memberships and offerings that Audiences have purchased on Attractr.
      </p>
      <ul>
        <li>
          <strong>Payments:</strong> As a creator, you make offerings available on Attractr on a
          one-time subscription basis. We handle payments issues such as fraud, chargebacks, and
          resolution of payments disputes. We try to provide timely access to the funds you’ve
          earned on Attractr, but you may occasionally experience delays in accessing these funds.
          We may also block or withhold access to these funds for violations of our terms or
          policies or for compliance reasons, including collecting tax reporting information. When
          this happens, we try to communicate the reason to you promptly. If you have questions
          about access to funds being blocked or withheld, please reach out to us. In order to
          protect creators, we may block members’ payments if we believe them to be fraudulent.
          Sometimes activities like refunds can put your account balance into the negative. If your
          balance becomes negative, we reserve the right to recover those funds from future
          payments.
        </li>
        <li>
          <strong>Membership modification:</strong> If a Creator changes any benefits or pricing of
          memberships, the change will only be effective for new subscribers. Creators will have to
          deliver the benefits to already-subscribed members as they purchase for the rest of the
          membership duration.
        </li>
        <li>
          <strong>Membership cancellation and refunds:</strong> If a Creator cancels a membership,
          the Creator will have to refund if the audience hasn't received any perks yet. If
          audiences have already partially consumed the membership benefits, Creators will have to
          provide an alternative subscription option or deliver the rest of the benefits as
          purchased. If a Creator doesn’t provide any perks for the effective duration, then
          Attractr will refund the amount paid to the audience subtracting from your balance.
        </li>
        <li>
          <strong>Fees:</strong> As a creator, there are four fees associated with your offerings
          and paid memberships on Attractr. The general nature of these fees is summarized below,
          but the most current information is found in our Help Center:
          <ul>
            <li>
              The platform fee, which is calculated as a percentage of successfully processed
              payments;
            </li>
            <li>
              The payment processing fee, which is calculated as a percentage of successfully
              processed payments and a flat fee per successfully processed payment and relates to
              the cost of processing payments from patrons, including recurring billing, recovering
              declined payments, and fighting fraud;
            </li>
            <li>
              The payout fee, which is a fee for transferring money you’ve earned on Attactr; and
            </li>
            <li>
              The currency conversion fee, which is calculated as a percentage of successfully
              processed payments made in a currency other than your payout currency.
            </li>
          </ul>
          You can see the fees for any membership subscription on your creator dashboard, though
          please note that this dashboard does not list the payout fee described above.
          <br />
          If a member pays in a currency that is different from the currency associated with their
          payment instrument, then the financial institution associated with that payment instrument
          may charge them a foreign transaction fee for that payment. We are not applying this fee
          and have no control over it, have no knowledge of whether the fee is applied, and do not
          receive any of the funds from that fee.
          <br />
          Even if a member pays in the same currency as the currency associated with their payment
          instrument, when a member’s payment is processed in a country different from the one
          associated with their payment instrument, then the financial institution associated with
          that payment instrument may charge them a foreign transaction fee for that payment. We are
          not applying this fee, have no knowledge of whether the fee is applied, and do not receive
          any of the funds from that fee, but our choices about payment processing may impact or
          otherwise give rise to this fee.
        </li>
        <li>
          <strong>Tax:</strong> In order to satisfy our tax obligations, we collect tax
          identification information and, in certain circumstances, report this information and
          earnings to tax authorities as legally required. You are responsible for reporting any
          income, withholding, or other earnings-based taxes which may be due as a result of money
          you’ve earned on Attractr. You can learn more in our Help Center.
          <br />
          We are additionally responsible for a number of transactional taxes across the world.
          These include, but are not limited to, Valued Added Tax (“VAT”), Goods and Services Tax
          (“GST”) and other local service or sales taxes in non-Canada locations (collectively
          referred to as “VAT”), as well as provincial sales tax in Canada. To learn more about how
          we handle transaction taxes, please check our Help Center articles. In locales where we
          are required to collect transaction tax from members, invoices presented to and payments
          processed from patrons will include the tier or custom payment amount plus the applicable
          tax. Applicable tax will be charged in addition to the price of a relevant transaction
          based upon the benefits that you indicate that you will be offering. We will use the
          benefit categories that you assign to your membership or offering to calculate, collect,
          and remit the applicable taxes. You are responsible for ensuring that all of your benefits
          are properly categorized using good faith efforts and to the best of your ability using
          the available tools. You agree to provide accurate information when you are engaging with
          the tier tools, sales tax weighting tools and, generally, when listing and categorizing
          benefits transferred within membership tiers and offerings. Once settled, the tax amount
          will be automatically deducted, and we will remit directly to the applicable taxing
          authority. A record of the transaction tax portion of the payment will be available on
          your earnings details page.
          <br />
          ​​Any amounts payable under this agreement between a creator and Attractr are inclusive of
          any VAT or sales tax, unless mentioned otherwise. Please refer to our Help Center in this
          regard. You agree that you will provide us with any information and documents for accurate
          tax determination and compliance, where and as we request or as otherwise required.
        </li>
        <li>
          <strong>Restrictions:</strong> We don’t allow creations and benefits that violate our
          terms or policies. You can learn more by visiting our Community Guidelines. A high level
          summary of those rules is that we don’t allow:
          <ul>
            <li>illegal creations or benefits;</li>
            <li>creations or benefits that are abusive towards other people;</li>
            <li>
              creations or benefits that use others’ intellectual property, unless you have written
              permission to use it, or your use is protected as fair use;
            </li>
            <li>or creations or benefits with real people engaging in sexual acts.</li>
          </ul>
          <p>
            We are constantly experimenting with product features to make Attractr better and may
            add, remove, or test features with a subset of creators. Access to these features is not
            guaranteed and may not be subject to your control. Instead, we may enable and disable
            these early-access, beta, or test features at our sole discretion. These features may
            also be subject to additional restrictions and limitations, including some that we
            describe in our Help Center.
          </p>
        </li>
      </ul>
      <h3>4. Audience Terms</h3>
      <p>
        Audiences can search, discover Creators and their project through Video, hang out at a free
        Live Room activities, subscribe or purchase a paid subscription on Attractr. As an audience,
        you’re supporting a creator, contributing to their creation, and may receive special
        benefits like shoutouts, exclusive creations, members-only content, and the other perks. You
        pay on a one-time subscription basis; Attractr is the billing entity that is associated with
        this charge.
      </p>
      <p>
        The timing and amount of each subscription depends on the type of offering or subscription
        you select and the creator you support. You can see the details prior to purchasing the
        offering or starting the subscription and you can view all your active subscriptions and
        billing history in your payment history.
      </p>
      <ul>
        <li>
          <strong>Membership modifications:</strong> If a Creator changes any benefits or pricing of
          memberships, the change will only be effective for new subscribers, and already-subscribed
          members will keep and receive the benefits as they purchased for the rest of the
          membership duration.
        </li>
        <li>
          <strong>Membership cancellation and refund:</strong> If a Creator cancels a membership,
          you may get a refund if you haven’t received any perks yet. If you have already partially
          consumed the membership benefits, you won’t get a refund, but Creators will provide an
          alternative subscription option or deliver the rest of the benefits as purchased.
          <br />
          You may want to cancel a membership. Canceling your membership may result in your loss of
          access to membership subscription benefits, including a creator’s members-only live
          experience and other benefits. To receive a full refund, you must cancel within 48 hours
          of subscriptions made.
        </li>
        <li>
          <strong>Payment:</strong> If you pay in a currency that is different from the currency
          associated with your payment instrument, then the financial institution associated with
          that payment instrument may charge you a foreign transaction fee for that payment. We are
          not applying this fee and have no control over it, have no knowledge of whether the fee is
          applied, and do not receive any of the funds from that fee.
          <br />
          Even if you pay in the same currency as the currency associated with your payment
          instrument, when your payment is processed in a country different from the one associated
          with your payment instrument, then the financial institution associated with that payment
          instrument may charge you a foreign transaction fee for that payment. We are not applying
          this fee, have no knowledge of whether the fee is applied, and do not receive any of the
          funds from that fee, but our choices about payment processing may impact or otherwise give
          rise to this fee.
        </li>
        <li>
          <strong>Tax:</strong> If you are located in a jurisdiction in which Attractr is required
          to charge and collect tax, for example VAT or sales tax, then this tax is added to the
          total charge. Except in limited circumstances, this tax is shown to you when purchasing an
          offering or starting a paid subscription. Attractr remits all tax collected to the
          applicable taxing authority. As tax is largely dependent on your location, you agree to
          keep your location complete and up to date in order to enable accurate collection of
          taxes.
        </li>
        <li>
          <strong>Attending in membership benefits:</strong> You are responsible for confirming that
          you, and anyone you invite, meet minimum age, proficiency, fitness, or other requirements
          to subscribe to and attend membership benefits that offer activities (i.e., online
          workshop, backstage tour, etc.). You are responsible for informing the Creator of any
          medical or physical conditions, or other circumstances that may impact your ability to
          participate, attend, or use the Creator’s membership. Except where expressly authorized,
          you may not allow any person to join a Creator’s membership unless they are included as an
          additional audience during the booking process/membership effective period.
        </li>
      </ul>
      <h3>5. Attractr's Role</h3>
      <p>
        We offer you the right to use a platform that enables Members to publish, offer, search for,
        engage with, and subscribe to Creators Content. While we work hard to ensure our Members
        have great experiences using Attractr, we do not and cannot control the conduct of Audiences
        and Creators. You acknowledge that Attractr has the right, but does not have any obligation,
        to monitor the use of the Attractr Platform and verify information provided by our Members.
        For example, we may review, disable access to, remove, or edit Content to: (i) operate,
        secure and improve the Attractr Platform (including for fraud prevention, risk assessment,
        investigation, and customer support purposes); (ii) ensure Members’ compliance with these
        Terms; (iii) comply with applicable law or the order or requirement of a court, law
        enforcement, or other administrative agency or governmental body; (iv) address Content that
        we determine is harmful or objectionable; (v) take actions set out in these Terms; and (vi)
        maintain and enforce any quality or eligibility criteria, including by removing Listings
        that don’t meet quality and eligibility criteria. Members acknowledge and agree that
        Attractr administers its Additional Legal Terms, Policies, and Community Guidelines,
        including decisions about whether and how to apply them to a particular situation, at its
        sole discretion. Members agree to cooperate with and assist Attractr in good faith, and to
        provide Attractr with such information and take such actions as may be reasonably requested
        by Attractr with respect to any investigation undertaken by Attractr regarding the use or
        abuse of the Attractr Platform. Attractr is not acting as an agent between Audience and
        Creator except for where Attractr Payments acts as a collection agent as provided in the
        Payments Terms.
      </p>
      <h3>6. Your Content & Creations</h3>
      <p>
        Creators keep full ownership of their creations (Listing, Video, Creator Content), but we
        need licenses from you for this material in order to operate Attractr’s services
        effectively. For clarity, you keep full ownership over your Content on Attractr; we are not
        buying your intellectual property rights or leasing them from you for our gain. We will
        never try to steal your creations, use them in an exploitative way, or seek to profit off of
        them by any means other than facilitating memberships and offerings you’ve configured on
        Attractr. By making creations available on Attractr or otherwise posted on Attractr, you
        grant us a royalty-free, perpetual, irrevocable, non-exclusive, sublicensable, worldwide
        license covering your Content in all formats and channels now known or later developed
        anywhere in the world to use, copy, reproduce, store, translate, transmit, distribute,
        perform, prepare derivative works, publicly display, and display in connection with any
        name, username, voice, or likeness provided in connection with it. It’s important for us to
        operate our platform, including hosting Listing and Video, providing and promoting
        memberships to Audiences, and enabling community features.
      </p>
      <p>
        You may not make available creations or otherwise make posts on Attractr that infringe
        others’ intellectual property or proprietary rights. Attractr reserves the right to remove
        creations and other material that violate Attractr’s terms and policies, including these
        Terms of Use and the Community Guidelines. We respond to complaint notifications of claimed
        copyright infringement and have adopted and reasonably implemented a policy for terminating,
        in appropriate circumstances, the accounts of creators and patrons who are the subject of
        repeated compliant notifications of claimed copyright infringement or other evidence that
        suggests a pattern of repeat infringement.
      </p>
      <h3>7. Acknowledgement</h3>
      <p>
        You acknowledge and agree that: Attractr will not be liable or responsible for any Creator
        Content or other offering requested by Member you make; the Attractr watermark on each Video
        must remain intact and you agree not to edit, change, modify, cover, or remove the watermark
        from any Attractr Video or assist or encourage any third-party to do so;
      </p>
      <h3>8. Termination</h3>
      <p>
        You may terminate this agreement at any time by sending us an email or by deleting your
        account. Attractr may terminate this agreement and your account for any reason by giving you
        30 days’ notice via email or using any other contact information you have provided for your
        account. Attractr may also terminate this agreement immediately and without notice and stop
        providing access to the Attractr Platform if you breach these Terms, you violate our
        Policies, you violate applicable laws, or we reasonably believe termination is necessary to
        protect Attractr, its Members, or third parties. If your account has been inactive for more
        than two years, we may terminate your account without prior notice.
      </p>
      <h3>9. Abusive conduct</h3>
      <p>
        You are responsible for all activity on your account. If you violate our terms or policies,
        then we may terminate your account. Don’t do anything illegal, abusive towards others, or
        that abuses our site in a technical way. You can find more detailed information in the
        Community Guidelines.
      </p>
      <h3>10. Member Violations</h3>
      <p>
        If (i) you breach these Terms, our Policies, or our Community Guideline, (ii) you violate
        applicable laws, regulations, or third-party rights, or (iii) Attractr believes it is
        reasonably necessary to protect Attractr, its Members, or third parties; Attractr may, with
        or without prior notice:
      </p>
      <ul>
        <li>
          Suspend or limit your access to or use of the Attractr Platform and/or your account;
        </li>
        <li>Suspend or remove Listings, Reviews, or other Content;</li>
        <li>Cancel pending or confirmed bookings; or</li>
        <li>Suspend or revoke any special status associated with your account.</li>
      </ul>
      <p>
        For minor violations or where otherwise appropriate as Attractr determines in its sole
        discretion, you will be given notice of any intended measure by Attractr and an opportunity
        to resolve the issue.
      </p>
      <h3>11. Modification</h3>
      <p>
        Attractr may modify these Terms at any time. When we make material changes to these Terms,
        we will post the revised Terms on the Attractr Platform and update the “Last Updated” date
        at the top of these Terms. We will also provide you with notice of any material changes by
        email at least 30 days before the date they become effective. If you disagree with the
        revised Terms, you may terminate this agreement immediately as provided in these Terms. If
        you do not terminate your agreement before the date the revised Terms become effective, your
        continued access to or use of the Attractr Platform will constitute acceptance of the
        revised Terms.
      </p>
      <h3>12. Indemity</h3>
      <p>
        You will indemnify us from all losses and liabilities, including legal fees, that arise from
        these terms or relate to your use of Attractr. We reserve the right to exclusive control
        over the defense of a claim covered by this clause. If we use this right, then you will help
        us in our defense. Your obligation to indemnify under this clause also applies to our
        subsidiaries, affiliates, officers, directors, employees, agents, and third-party service
        providers.
      </p>
      <h3>13. Warranty disclaimer</h3>
      <p>
        Attractr is provided “as is” and without warranty of any kind. Any warranty of
        merchantability, fitness for a particular purpose, non-infringement, and any other warranty,
        whether express or implied, is excluded and disclaimed to the greatest extent permitted by
        law. The disclaimers of warranty under this clause also apply to our subsidiaries,
        affiliates, and third-party service providers.
      </p>
      <h3>14. Limitations on Liability</h3>
      <p>
        To the greatest extent permitted by law, we are not liable to you for any incidental,
        consequential, or punitive damages arising out of these terms, or your use or attempted use
        of Attractr. To the greatest extent permitted by law, our liability for damages is limited
        to the amount of money we have earned through your use of Attractr. We are specifically not
        liable for loss associated with unfulfilled benefits and from losses caused by conflicting
        contractual agreements. For this clause, “we” and “our” is defined to include our
        subsidiaries, affiliates, officers, directors, employees, agents, and third-party service
        providers.
      </p>
      <h3>15. Dispute resolution</h3>
      <p>
        We encourage members to reach us first to resolve an issue in a friendly manner. If a
        dispute does arise out of these terms or in relation to your use of Attractr, then the
        dispute will be resolved in the federal or provincial courts located in Montreal, Quebec,
        Canada. Both parties consent to the exclusive jurisdiction and venue of the Montreal courts
        for the purpose of resolving any such dispute. Quebec law, excluding its conflict of law
        provisions, governs these terms, all other Patreon policies, and any dispute that arises
        between you and Attractr.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
