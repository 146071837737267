import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPhoto.module.css';

const IconPhoto = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="32"
      width="32"
    >
      <path d="M27 3a4 4 0 0 1 4 4v18a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4zM8.887 19.038l-.094.083L3 24.914V25a2 2 0 0 0 1.85 1.995L5 27h13.085l-7.878-7.879a1 1 0 0 0-1.32-.083zm12.5-6-.094.083-7.129 7.129L20.915 27H27a2 2 0 0 0 1.995-1.85L29 25v-5.585l-6.293-6.294a1 1 0 0 0-1.32-.083zM27 5H5a2 2 0 0 0-2 2v15.084l4.379-4.377a3 3 0 0 1 4.098-.135l.144.135 1.128 1.128 7.13-7.128a3 3 0 0 1 4.098-.135l.144.135L29 16.585V7a2 2 0 0 0-1.85-1.995zM8 7a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
    </svg>
  );
};

const { string } = PropTypes;

IconPhoto.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPhoto.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPhoto;
