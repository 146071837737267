import React from 'react';
import { string, object } from 'prop-types';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import classNames from 'classnames';
import moment from 'moment';

import css from './AddToCalendar.module.css';

// calendar
const CALENDAR_OPTIONS = ['Apple', 'Google', 'Outlook.com', 'Yahoo', 'iCal'];
const BUTTON_STYLE = 'custom';
const TRIGGER = 'click';
const LIST_STYLE = 'modal';
const TIMEZONE = 'currentBrowser';
// icons
const HIDE_ICON_BUTTON = true;
const HIDE_CHECKMARK = true;
// css
const CUSTOM_CSS_URL = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/static/addToCalendar.css`;

const AddToCalendar = props => {
  const { rootClassName, className, bookingLabel, bookingData } = props;
  const classes = classNames(rootClassName || css.root, className);

  const bookingStartDate = moment(bookingData.start).format('YYYY-MM-DD');
  const bookingEndDate = moment(bookingData.end).format('YYYY-MM-DD');
  const bookingStartTime = moment(bookingData.start).format('HH:mm');
  const bookingEndTime = moment(bookingData.end).format('HH:mm');

  const addToCalendarProps = {
    name: bookingData.title,
    description: bookingData.description,
    location: bookingData.location,
    startDate: bookingStartDate,
    endDate: bookingEndDate,
    startTime: bookingStartTime,
    endTime: bookingEndTime,
    options: CALENDAR_OPTIONS,
    buttonStyle: BUTTON_STYLE,
    trigger: TRIGGER,
    label: bookingLabel,
    listStyle: LIST_STYLE,
    timeZone: TIMEZONE,
    hideIconButton: HIDE_ICON_BUTTON,
    hideCheckmark: HIDE_CHECKMARK,
    customCss: CUSTOM_CSS_URL,
  };
  return (
    <div className={classes}>
      <AddToCalendarButton {...addToCalendarProps} />
    </div>
  );
};

AddToCalendar.defaultProps = {
  className: null,
  rootClassName: null,

  bookingLabel: null,
};

AddToCalendar.propTypes = {
  className: string,
  rootClassName: string,

  bookingLabel: string,
  bookingData: object.isRequired,
};

export default AddToCalendar;
