import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink, Logo, IconLock, IconArrowHead } from '../../';

import LockWrapperIcon from './LockWrapperIcon';
import css from './EditListingWizardTabNav.module.css';

const Tab = props => {
  const {
    className,
    linkClassName,
    selectedLinkClassName,
    id,
    disabled,
    text,
    selected,
    linkProps,
  } = props;
  const linkClasses = classNames(css.link, linkClassName, {
    [css.selectedLink]: selected,
    [selectedLinkClassName]: selected,
    [css.disabled]: disabled,
  });

  const renderEditListingIcon = (
    <LockWrapperIcon show={disabled}>
      <IconLock />
    </LockWrapperIcon>
  );

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
        {renderEditListingIcon}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = {
  className: null,
  linkClassName: null,
  selectedLinkClassName: null,
  disabled: false,
  selected: false,
};

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  linkClassName: string,
  selectedLinkClassName: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const EditListingWizardTabNav = props => {
  const {
    className,
    rootClassName,
    navWrapperRootClassName,
    tabRootClassName,
    linkClassName,
    selectedLinkClassName,
    titleClassName,
    tabs,
    title,
    showLogo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const navWrapperClasses = classNames(navWrapperRootClassName || css.navWrapper);
  const tabClasses = tabRootClassName || css.tab;
  const titleClasses = classNames(css.title, titleClassName);
  return (
    <nav className={classes}>
      <div className={navWrapperClasses}>
        {showLogo ? (
          <div className={css.logoWrapper}>
            <NamedLink className={css.logoContainer} name="ManageListingsPage">
              <Logo className={css.logo} format="mobile" />
            </NamedLink>
            <NamedLink className={css.saveAndExitLink} name="ManageListingsPage">
              <FormattedMessage id="EditExperienceWizard.saveAndExit" />
            </NamedLink>
          </div>
        ) : null}
        {title ? <h2 className={titleClasses}>{title}</h2> : null}
        <div className={classNames(css.arrowContainer, css.tab)}>
          <NamedLink name="ManageListingsPage" className={css.link}>
            <IconArrowHead direction="left" />
          </NamedLink>
        </div>
        {tabs.map((tab, index) => {
          const id = typeof tab.id === 'string' ? tab.id : `${index}`;
          return (
            <Tab
              key={id}
              id={id}
              className={tabClasses}
              linkClassName={linkClassName}
              selectedLinkClassName={selectedLinkClassName}
              {...tab}
            />
          );
        })}
      </div>
    </nav>
  );
};

EditListingWizardTabNav.defaultProps = {
  className: null,
  rootClassName: null,
  navWrapperRootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
  linkClassName: null,
  selectedLinkClassName: null,
  titleClassName: null,
  title: null,
  showLogo: false,
};

EditListingWizardTabNav.propTypes = {
  className: string,
  rootClassName: string,
  navWrapperRootClassName: string,
  tabRootClassName: string,
  linkClassName: string,
  selectedLinkClassName: string,
  titleClassName: string,
  title: string,
  showLogo: bool,
  tabs: arrayOf(object).isRequired,
};

export default EditListingWizardTabNav;
