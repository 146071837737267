import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { NamedLink } from '..';
import config from '../../config';

import css from './PaymentBreakdown.module.css';
import { createSlug } from '../../util/urlHelpers';

const findConfigOptionLabels = (configKey, filterConfig, selectedKeys) => {
  const hasSelectedKeys = selectedKeys?.length > 0;
  if (!hasSelectedKeys) {
    return null;
  }

  const options = findOptionsForSelectFilter(configKey, filterConfig);
  const selectedOptions = options.filter(o => selectedKeys.includes(o.key));
  const selectedOptionLabels = selectedOptions.map(o => o.label);
  const joinedOptionLabels = selectedOptionLabels.join(', ');

  return joinedOptionLabels;
};

const LineItemExperienceDetails = props => {
  const { listing, timeslot } = props;
  const { title, publicData } = listing.attributes;

  const filterConfig = config.custom.filters;
  const languages = findConfigOptionLabels('language', filterConfig, publicData?.language);

  return (
    <div className={css.details}>
      <h4 className={css.detailsTitle}>
        <FormattedMessage id="PaymentBreakdown.experienceDetails" />
      </h4>
      <div className={css.lineItems}>
        {listing.id ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="PaymentBreakdown.experienceName" />
            </span>
            <NamedLink
              className={css.experienceItemValue}
              name="ExperiencePage"
              params={{ id: listing.id.uuid, slug: createSlug(title) }}
            >
              {title}
            </NamedLink>
          </div>
        ) : null}
        {timeslot?.name ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="PaymentBreakdown.timeslotName" />
            </span>
            <span className={css.itemValue}>{timeslot.name}</span>
          </div>
        ) : null}
        {languages ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="PaymentBreakdown.experienceHosted" />
            </span>
            <span className={css.itemValue}>{languages}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LineItemExperienceDetails;
