import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatDuration } from '../../util/membership';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  Modal,
  ResponsiveImage,
  PrimaryButton,
  SecondaryButton,
  InlineTextButton,
  IconCheckmark,
  IconArrowHead,
} from '..';
import config from '../../config';
import classNames from 'classnames';

import css from './MembershipModal.module.css';

const { Money } = sdkTypes;

const MembershipPerks = props => {
  const { membership, perksConfig, onReturn } = props;
  const selectedPerks = perksConfig.filter(p => membership.perks.includes(p.key));
  return (
    <div className={css.membershipPerks}>
      <h2 className={css.membershipPerksName}>
        <InlineTextButton className={css.backButton} onClick={onReturn}>
          <IconArrowHead className={css.backIcon} direction="left" />
        </InlineTextButton>
        {membership.name}
      </h2>
      <ul className={css.membershipPerksList}>
        {selectedPerks.map(p => {
          return (
            <li className={css.membershipPerksListItem} key={p.key}>
              <IconCheckmark className={css.checkmarkIcon} size="small" />
              <span>{p.label}</span>
            </li>
          );
        })}
      </ul>
      <SecondaryButton type="button" className={css.membershipPerksReturnButton} onClick={onReturn}>
        <FormattedMessage id="MembershipModal.returnButton" />
      </SecondaryButton>
    </div>
  );
};

const Membership = props => {
  const {
    intl,
    membership,
    perksConfig,
    isOwnListing,
    isViewMembershipIdActive,
    currentUserMemberships,
    handlePayment,
    setViewMembershipId,
  } = props;
  const { name, price, duration, perks } = membership;

  const priceMoney = new Money(price.amount, price.currency);
  const formattedPrice = formatMoney(intl, priceMoney);

  const hasMembership = !!currentUserMemberships.find(m => m.id === membership.id);
  const hasPerks = perks?.length > 0;

  const buttonDisabled = isOwnListing || hasMembership;
  return (
    <div className={css.membership}>
      <div className={css.membershipContent}>
        <h3 className={css.membershipName}>
          {name}
          {hasMembership ? (
            <span className={css.activeTag}>
              <FormattedMessage id="MembershipModal.active" />
            </span>
          ) : null}
        </h3>
        <div>
          <span>{formattedPrice}</span>
          <span className={css.separator}>·</span>
          <span>{formatDuration(intl, duration)}</span>
          {hasPerks ? (
            <>
              <span className={css.separator}>·</span>
              <InlineTextButton
                className={css.perksButton}
                onClick={() => setViewMembershipId(membership.id)}
              >
                <FormattedMessage id="MembershipModal.perks" />
              </InlineTextButton>
            </>
          ) : null}
        </div>
      </div>
      <PrimaryButton
        className={css.membershipButton}
        onClick={() =>
          handlePayment({ membership, paymentType: config.paymentTypes['membership'] })
        }
        disabled={buttonDisabled}
      >
        <FormattedMessage id="MembershipModal.membershipButton" />
      </PrimaryButton>
      {isViewMembershipIdActive ? (
        <MembershipPerks
          membership={membership}
          perksConfig={perksConfig}
          onReturn={() => setViewMembershipId(null)}
        />
      ) : null}
    </div>
  );
};

const { oneOfType, array, func, string, bool, object } = PropTypes;

Membership.propTypes = {
  membership: object.isRequired,
  perksConfig: array.isRequired,
  isViewMembershipIdActive: bool.isRequired,
  setViewMembershipId: func.isRequired,
};

const MembershipModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    isOwnListing,
    hideContactButton,
    authorPayoutsEnabled,
    listing,
    authorDisplayName,
    perksConfig,
    currentUserMemberships,
    handlePayment,
    onCloseModal,
    onOpenEnquiryModal,
    onManageDisableScrolling,
  } = props;

  const [viewMembershipId, setViewMembershipId] = useState(null);

  const { title, publicData } = listing?.attributes;

  const hasImages = listing?.images?.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const membershipsFromPublicData = publicData?.memberships;
  const validMemberships = authorPayoutsEnabled
    ? membershipsFromPublicData
    : isOwnListing
    ? membershipsFromPublicData
    : [];

  const hasMemberships = validMemberships?.length > 0;
  const memberships = hasMemberships ? publicData?.memberships : null;

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithPerks]: !!viewMembershipId,
  });

  // Render active memberships, or show that the listing
  // doesn't have any active ones.
  const renderMemberships = hasMemberships ? (
    <div className={css.memberships}>
      {memberships.map(m => {
        const isViewMembershipIdActive = viewMembershipId === m.id;

        return (
          <Membership
            key={m.id}
            intl={intl}
            membership={m}
            perksConfig={perksConfig}
            isOwnListing={isOwnListing}
            isViewMembershipIdActive={isViewMembershipIdActive}
            currentUserMemberships={currentUserMemberships}
            handlePayment={handlePayment}
            setViewMembershipId={setViewMembershipId}
          />
        );
      })}
    </div>
  ) : (
    <div className={css.memberships}>
      <p className={css.noMembershipsText}>
        <FormattedMessage
          id="MembershipModal.noMemberships"
          values={{ listingTitle: <strong>{title}</strong> }}
        />
      </p>
    </div>
  );

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      removeCloseMessage
    >
      <div className={css.modalHeader}>
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
        />
        <div className={css.modalHeaderContent}>
          <h2 className={css.modalTitle}>
            <FormattedMessage id="MembershipModal.title" />
          </h2>
          <p className={css.modalSubTitle}>{title}</p>
        </div>
      </div>
      {renderMemberships}
      {!isOwnListing && !hideContactButton ? (
        <div className={css.modalFooter}>
          {hasMemberships ? (
            <span className={css.contactNotice}>
              <FormattedMessage id="MembershipModal.contactNotice" />
            </span>
          ) : null}
          <SecondaryButton className={css.contactButton} onClick={onOpenEnquiryModal}>
            <FormattedMessage id="MembershipModal.contact" values={{ authorDisplayName }} />
          </SecondaryButton>
        </div>
      ) : null}
    </Modal>
  );
};

MembershipModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  isOpen: false,
};

MembershipModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  isOpen: bool.isRequired,
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]).isRequired,
  authorDisplayName: string,
  perksConfig: array.isRequired,
  onOpenEnquiryModal: func,
  intl: intlShape.isRequired,
};

export default injectIntl(MembershipModal);
