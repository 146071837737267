import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>
        This is a draft during the test phase. The actual legal documents to be updated by the best
        launch.
      </p>

      <p>
        Attractr is a marketing platform that helps performing arts to thrive and grow business and
        creativity by connecting audiences from behind the senses. “Attractr,” “we,” “us,” and “our”
        refer to this platform, and the services offered by Attractr, including Attractr embeds,
        www.attractr.live, and Attractr’s other websites. This Privacy Policy is part of our Terms
        of Use, and describes how we collect, use, and share information.
      </p>

      <h3>1. Information You Provide Attractr</h3>
      <p>
        This is information that you provide to us through text fields, such as your name, payment
        information, and benefits. The information of this type that we require you to provide
        differs depending on whether you create an account, become a creator, subscribe to
        membership or other offering, otherwise engage with a creator, and typically includes at
        least:
      </p>
      <ul>
        <li>first and last name/surname; </li>
        <li>email address; </li>
        <li>phone number; </li>
        <li>username; </li>
        <li>password; </li>
        <li>birthday;</li>
        <li>and/or county and state/province of residence.</li>
      </ul>

      <p>
        You may also have the option to sign up for Attractr using a Facebook or Google account. We
        will ask for permission to access basic information from your Facebook or Google account,
        such as your userID, full name and email address. You can choose to stop sharing that
        information with us at any time by going to Facebook or Google to remove Attractr’s access
        to that account and your ability to use it to log in to Attractr. In order to use an account
        with one of these companies to sign up for Attractr, these companies may require you to
        share personal data with them, including information about your usage of Attractr.
      </p>

      <p>
        You may also have the option to add more information to your public profile, such as a
        location, social media links, and an about section, which we store along with the other
        information that we collect.
      </p>

      <h3>2. Member</h3>
      <p>
        A member is someone who searches for, follows a profile, attends, subscribes and/or makes a
        purchase for Listing on Attractr. As a member, you may be required to provide your payment
        information to our payment partners. You can see the Privacy Policy for these payment
        partners on the Stripe and Checkout sites. Attractr stores the expiry and postal code
        associated with your payment card, but does not store your full payment card number.
        Instead, Attractr uses a service provider to create a token that represents your card’s
        primary account number, which Attractr then stores. We collect and process information about
        the creators you subscribe to and/or support, the memberships you purchase, and what
        benefits you receive.
      </p>

      <h3>3. Creators</h3>
      <p>
        A creator is someone who creates a Listing on Patreon to engage with audiences who search
        for, click Video, engage with, attend, or subscribe to Creator Content. To become a creator,
        you must create or be invited to a Show Page that describes a project that you are
        promoting, and a Live Room that offers membership. To receive payouts, you have to create an
        account with Stripe. You will also be required to provide your bank account information to
        process payouts. You must also provide us with additional information for tax purposes.
        Depending on your location and the type of creations that you create, you may have to
        provide Attractr some combination of the following:
      </p>
      <ul>
        <li>email address; </li>
        <li>phone number; </li>
        <li>username; </li>
        <li>password; </li>
        <li>first and last name/surname; </li>
        <li>address; </li>
        <li>social security number and/or employer identification number; </li>
        <li>country of citizenship; </li>
        <li>tax identification number and/or VAT number; </li>
        <li>date of birth; </li>
        <li>your nonprofit registration number, if you are a nonprofit business; </li>
        <li>bank account information; </li>
        <li>and/or copies of your ID card and proof of residency.</li>
      </ul>

      <p>
        Attractr maintains a Data Processing Agreement governing how creators are permitted to
        process the information that is shared with them. We refer to this as the Creator Privacy
        Promise, which details a creator’s promise to protect the privacy of their members during
        and beyond their Attractr relationship. You can view the Creator Privacy Promise here.
      </p>

      <h3>4. Automatically Collected Information</h3>
      <p>
        We automatically receive information when you view Creator Content on or otherwise interact
        with Attractr, even if you have not created an account. For example, when you visit
        attract.live, view or interact with a Attractr embed on another website, sign into
        Attractr’s services, or view or interact with an email from Attractr, we may automatically
        receive information about you, including:
      </p>
      <ul>
        <li>your IP address; </li>
        <li>your approximate location (typically derived from your IP address); </li>
        <li>your browser and/or device type; </li>
        <li>your operating system; your language settings; </li>
        <li>the referring web page (including parameters contained therein); </li>
        <li>your mobile carrier; </li>
        <li>device information (including device and application IDs); </li>
        <li>your search terms (including those not submitted as queries); </li>
        <li>the links you click; </li>
        <li>the pages you visit/the creations you load or request; </li>
        <li>
          session length, dwell time, and duration of media experiences (including view time);{' '}
        </li>
        <li>and/or cookie information (further detailed in our Cookie Policy).</li>
      </ul>

      <h3>6. How We Use Information</h3>

      <ul>
        <li>
          verify your identity to comply with industry requirements, self-regulatory guidelines, and
          Canada, Quebec, and international laws;{' '}
        </li>
        <li>enable you to sign in to your account; </li>
        <li>enable you to subscribe to creators and/or purchase subscriptions on Attractr; </li>
        <li>process payments and payouts; </li>
        <li>apply VAT and/or sales tax; </li>
        <li>deliver membership benefits; </li>
        <li>
          send you messages and notifications, including push notifications, email, and SMS/MMS as
          controlled by your notification preferences;{' '}
        </li>
        <li>
          provide you with information about Attractr, Attractr features, and your memberships and
          offerings on Attractr;{' '}
        </li>
        <li>provide you with customer support and reply to your questions; </li>
        <li>promote your Attractr account and/or creator Listing for greater discoverability; </li>
        <li>personalize the Attractr experience, including recommending creators to patrons; </li>
        <li>
          market Attractr features and services to you or to audiences similar to you, as controlled
          by your marketing preferences;{' '}
        </li>
        <li>organize and tailor in-person and online events; </li>
        <li>understand and analyze how you use the service; </li>
        <li>
          conduct research and development to improve Attractr and develop our products and their
          features;{' '}
        </li>
        <li>
          prevent fraud, abuse, and illegal activities on Attractr, including using automated
          processing and manual review;{' '}
        </li>
      </ul>

      <h3>7. Information Shared with Creators</h3>

      <p>
        By subscribing, purchasing a membership or support on Attractr that is offered by a creator,
        or otherwise engaging with or becoming an audience of a creator, you agree to share personal
        data with that creator including:
      </p>

      <ul>
        <li>your full name in your Attractr profile; </li>
        <li>your email address; </li>
        <li>your phone number;</li>
        <li>your location (country and city)</li>
        <li>your other Attractr profile information, including your avatar image; </li>
        <li>all information about your membership, including amount and start date; </li>
        <li>
          and some aggregated data about how you use Attractr, including analytics like Live Room
          attendance frequency, Video views, and/or social post engagement.
        </li>
      </ul>

      <h3>8. Information Shared with the Public</h3>

      <p>
        The following information is publicly accessible or otherwise shared with members of the
        public:
      </p>

      <p>
        <strong>Member:</strong>
      </p>
      <ul>
        <li>Aggregated and otherwise non-personal data for business development purpose only. </li>
        <li>Comments and reviews.</li>
        <li>Profile information including your profile picture.</li>
        <li>the creators you support; </li>
        <li>the Video you’ve liked; </li>
        <li>and the total number of comments & reviews you’ve made or received.</li>
      </ul>

      <p>
        <strong>Creator:</strong>
      </p>
      <ul>
        <li>
          your Attractr profile, including your user name, profile picture, social media accounts
          you’ve linked, location information you’ve added and, Listing you’ve created, Video you
          uploaded, and the total number of comments you’ve made;{' '}
        </li>
        <li>
          All Listing (unless it’s closed), including a description of Show, Video you uploaded,
          descriptions of your membership tiers, the titles of all of your Live Room timeslots.{' '}
        </li>
      </ul>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
