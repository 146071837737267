import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHeart.module.css';

const IconHeart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
      strokeWidth="2"
      height="24"
      width="24"
    >
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.406 7.627c-4.934-.431-9.275 3.104-9.72 8.188-.224 2.565.64 4.467 2.114 6.55l13.453 16.548 8.783.769L43.14 25.72c1.815-1.796 2.995-3.504 3.22-6.067.444-5.083-3.21-9.432-8.144-9.864l-8.783-.768a8.61 8.61 0 0 0-4.274.721 8.778 8.778 0 0 0-3.97-1.346l-8.784-.769Z"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M37.562 18.883c.444-5.082-3.208-9.431-8.14-9.863-3.982-.348-7.591 2.103-9.07 5.713-.83-3.811-3.967-6.757-7.95-7.106-4.932-.431-9.27 3.104-9.715 8.188-.225 2.566.638 4.468 2.112 6.551l13.446 16.547L34.343 24.95c1.815-1.796 2.994-3.504 3.219-6.068Z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconHeart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHeart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHeart;
