import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const OrganizationIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_12_313)">
        <path
          d="M12.1429 19.2857H0.714294V5.71428L6.42858 0.714279L12.1429 5.71428V19.2857Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1429 19.2857H19.2857V9.28571H12.1429"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.42859 19.2857V16.4286"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.28571 12.1429H8.57142"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.28571 7.85713H8.57142"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_313">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

OrganizationIcon.defaultProps = {
  className: null,
};

OrganizationIcon.propTypes = {
  className: string,
};

export default OrganizationIcon;
