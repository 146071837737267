import React from 'react';
import { array, string, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage } from '..';
import config from '../../config';
import classNames from 'classnames';

import css from './ExperienceCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getThemeInfo = (themeOptions, key) => {
  return themeOptions.find(c => c.key === key);
};

const ListingImage = props => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ExperienceCardComponent = props => {
  const {
    className,
    rootClassName,
    aspectWrapperClassName,
    listing,
    renderSizes,
    filtersConfig,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const themeOptions = findOptionsForSelectFilter('theme', filtersConfig);
  const theme = publicData ? getThemeInfo(themeOptions, publicData.theme) : null;

  return (
    <NamedLink className={classes} name="ExperiencePage" params={{ id, slug }}>
      <div className={css.threeToTwoWrapper}>
        <div className={classNames(css.aspectWrapper, aspectWrapperClassName)}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landspace-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        {theme ? <span className={css.theme}>{theme.label}</span> : null}
      </div>
      <div className={css.info}>
        <span className={css.viewers}>
          <FormattedMessage id="ExperienceCard.viewers" />
        </span>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
      </div>
    </NamedLink>
  );
};

ExperienceCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  aspectWrapperClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
};

ExperienceCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  aspectWrapperClassName: string,
  filtersConfig: array,

  listing: object.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

export default ExperienceCardComponent;
