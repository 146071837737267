/**
 * Functional component that deals with multiple and single
 * availability exceptions, that we call timeslots.
 *
 * As Sharetribe Flex API doesn't support adding single timeslots
 * and rather use the availabilityPlan, we had to use exceptions
 * to manage timeslots.
 */
import React from 'react';
import { arrayOf, string, bool, func, object, oneOfType } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  DATE_TYPE_DATETIME,
  AVAILABILITY_EXCEPTION_METADATA_ACCESS_MEMBERS,
} from '../../util/types';
import { TimeRange, IconSpinner, IconTrash, IconRepeat } from '..';
import classNames from 'classnames';
import moment from 'moment';

import css from './EditExperienceAvailabilityPanel.module.css';

const formatRepeatingExceptionDates = exceptions => {
  const mappedEx = exceptions.map(e => {
    return moment(e.attributes.start).format('MMM DD');
  });
  const joinedEx = mappedEx.slice(0, -1).join(', ') + ' and ' + mappedEx.slice(-1);

  return exceptions.length > 1 ? joinedEx : mappedEx;
};

/**
 * Single availability exceptions
 */
const AvailabilityException = props => {
  const {
    timeZone,
    availabilityException,
    repeatingAvailabilityExceptions,
    availabilityExceptionMetadata,
    deleteExceptionInProgress,
    deleteExceptionId,
    onDeleteAvailabilityException,
  } = props;

  const { start, end, seats } = availabilityException.attributes;
  const { title, access } = availabilityExceptionMetadata?.attributes || {};

  const isMainException = availabilityExceptionMetadata.type === 'exception';

  const repeat = isMainException && repeatingAvailabilityExceptions?.length > 0;
  const repeatingExceptionDates = formatRepeatingExceptionDates(repeatingAvailabilityExceptions);

  const showDeleteSpinner =
    deleteExceptionInProgress && deleteExceptionId?.uuid === availabilityException.id.uuid;

  const classes = classNames(css.exception, {
    [css.exceptionSubscription]: access === AVAILABILITY_EXCEPTION_METADATA_ACCESS_MEMBERS,
  });
  const exceptionAvailabilityDotClasses = classNames(css.exceptionAvailabilityDot, {
    [css.isAvailable]: seats > 0,
  });

  return (
    <div className={classes}>
      <div className={css.exceptionHeader}>
        <div className={css.exceptionAvailability}>
          <div className={exceptionAvailabilityDotClasses} />
          <div className={css.exceptionAvailabilityStatus}>
            {title ? (
              title
            ) : seats > 0 ? (
              <FormattedMessage id="EditExperienceAvailabilityPanel.exceptionAvailable" />
            ) : (
              <FormattedMessage id="EditExperienceAvailabilityPanel.exceptionNotAvailable" />
            )}
          </div>
        </div>
        <button
          type="button"
          className={css.deleteExceptionButton}
          onClick={() => onDeleteAvailabilityException({ id: availabilityException.id })}
        >
          {showDeleteSpinner ? (
            <IconSpinner className={css.deleteSpinnerIcon} />
          ) : (
            <IconTrash className={css.deleteIcon} />
          )}
        </button>
      </div>
      <div className={css.exceptionFooter}>
        <TimeRange
          className={css.timeRange}
          startDate={start}
          endDate={end}
          dateType={DATE_TYPE_DATETIME}
          timeZone={availabilityExceptionMetadata?.attributes?.timeZone || timeZone}
        />
        {repeat ? (
          <span className={css.repeat}>
            <IconRepeat className={css.repeatIcon} />
            <FormattedMessage
              id="EditExperienceAvailabilityPanel.repeat"
              values={{ dates: repeatingExceptionDates }}
            />
          </span>
        ) : null}
      </div>
    </div>
  );
};

AvailabilityException.defaultProps = {
  timeZone: null,
  availabilityExceptions: [],
  repeatingAvailabilityExceptions: [],
  availabilityExceptionMetadata: {},
  fetchExceptionsInProgress: bool.isRequired,
  deleteExceptionId: null,
  onDeleteAvailabilityException: null,
};

AvailabilityException.propTypes = {
  timeZone: string.isRequired,
  availabilityException: propTypes.availabilityException,
  repeatingAvailabilityExceptions: arrayOf(propTypes.availabilityException).isRequired,
  availabilityExceptionMetadata: propTypes.availabilityExceptionMetadata,
  deleteExceptionInProgress: bool.isRequired,
  deleteExceptionId: oneOfType([string, object]),
  onDeleteAvailabilityException: func.isRequired,
};

/**
 * Availability exceptions
 */
const AvailabilityExceptions = props => {
  const {
    timeZone,
    availabilityExceptions,
    publicDataAvailabilityExceptions,
    fetchExceptionsInProgress,
    deleteExceptionInProgress,
    deleteExceptionId,
    onDeleteAvailabilityException,
  } = props;

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;

  if (fetchExceptionsInProgress) {
    return (
      <div className={css.noExceptions}>
        <IconSpinner className={css.spinner} />
      </div>
    );
  } else if (exceptionCount === 0) {
    return (
      <div className={css.noExceptions}>
        <p className={css.noExceptionsText}>
          <FormattedMessage id="EditExperienceAvailabilityPanel.noExceptions" />
        </p>
      </div>
    );
  }
  return (
    <div className={css.exceptions}>
      {availabilityExceptions.map(availabilityException => {
        const availabilityExceptionMetadata = publicDataAvailabilityExceptions.find(
          e => e.id === availabilityException.id.uuid
        );

        const repeatingAvailabilityExceptionIds = publicDataAvailabilityExceptions
          .filter(e => e.attributes.mainExceptionId === availabilityException.id.uuid)
          .map(e => e.id);
        const repeatingAvailabilityExceptions = availabilityExceptions.filter(e =>
          repeatingAvailabilityExceptionIds.includes(e.id.uuid)
        );
        return (
          <AvailabilityException
            key={availabilityException.id.uuid}
            timeZone={timeZone}
            availabilityException={availabilityException}
            repeatingAvailabilityExceptions={repeatingAvailabilityExceptions}
            availabilityExceptionMetadata={availabilityExceptionMetadata}
            deleteExceptionInProgress={deleteExceptionInProgress}
            deleteExceptionId={deleteExceptionId}
            onDeleteAvailabilityException={onDeleteAvailabilityException}
          />
        );
      })}
    </div>
  );
};

AvailabilityExceptions.defaultProps = {
  timeZone: null,
  availabilityExceptions: [],
  publicDataAvailabilityExceptions: [],
  fetchExceptionsInProgress: false,
  deleteExceptionInProgress: false,
  deleteExceptionId: null,
  onDeleteAvailabilityException: null,
};

AvailabilityExceptions.propTypes = {
  timeZone: string.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException).isRequired,
  publicDataAvailabilityExceptions: arrayOf(propTypes.availabilityExceptionMetadata).isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  deleteExceptionInProgress: bool.isRequired,
  deleteExceptionId: string,
  onDeleteAvailabilityException: func.isRequired,
};

export default AvailabilityExceptions;
