import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { Slider, ExperienceCard } from '../../components';
import classNames from 'classnames';

import css from './SectionListings.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3;
const SLIDES_TO_SHOW_DESKTOP = 4;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 3;
const SLIDES_TO_SCROLL_DESKTOP = 4;

const SectionListings = props => {
  const {
    rootClassName,
    className,
    intl,
    listings,
    queryListingsInProgress,
    queryListingsError,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (queryListingsInProgress || queryListingsError) {
    return null;
  }

  const sliderTitle = intl.formatMessage({ id: 'SectionListings.title' });
  return (
    <div className={classes}>
      <Slider
        id="LandingPage.experiencesSlider"
        headerClassName={css.header}
        titleClassName={css.title}
        title={sliderTitle}
        slidesToShow={{
          tablet: SLIDES_TO_SHOW_TABLET,
          desktop: SLIDES_TO_SHOW_DESKTOP,
        }}
        slidesToScroll={{
          tablet: SLIDES_TO_SCROLL_TABLET,
          desktop: SLIDES_TO_SCROLL_DESKTOP,
        }}
      >
        {listings.map(l => {
          return <ExperienceCard key={l.id.uuid} listing={l} />;
        })}
      </Slider>
    </div>
  );
};

SectionListings.defaultProps = {
  rootClassName: null,
  className: null,
  queryListingsInProgress: false,
  queryListingsError: null,
};

const { arrayOf, bool, string } = PropTypes;

SectionListings.propTypes = {
  rootClassName: string,
  className: string,

  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
};

export default SectionListings;
