import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ManageListingCard.module.css';

const MenuIcon = props => {
  const { className, isActive } = props;
  const classes = classNames(css.menuIcon, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      width="16"
    >
      <path d="M3 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
    </svg>
  );
};

MenuIcon.defaultProps = {
  className: null,
  isActive: false,
};

const { bool, string } = PropTypes;

MenuIcon.propTypes = {
  className: string,
  isActive: bool,
};

export default MenuIcon;
