/**
 * Timeslots represents availabilityExceptions in ExperiencePage
 * as we couldn't use listings availabilityPlan to achieve the
 * timeslots logic as we wanted.
 */
import React, { useState } from 'react';
import { AVAILABILITY_EXCEPTION_METADATA_ACCESS_MEMBERS } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { Button, SecondaryButton, IconSpinner, IconPremium } from '..';

import TimeRange from './TimeRange';
import css from './BookingPanel.module.css';

const MAX_VISIBLE_TIMESLOTS = 3;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// As our UI only shows maximum of three timeslots at once,
// we need to slice timeslots and allow user to view more
// by clicking the button.
const visibleTimeslots = (timeslots, length) => timeslots.slice(0, length);

const Timeslot = props => {
  const {
    timeslot,
    timeslotMetadata,
    timeZone,
    currentUserMemberships,
    isOwnListing,
    onPaidBooking,
    onBooking,
  } = props;

  const { title, access, meeting } = timeslotMetadata?.attributes || {}; // Additional details of the single timeslot (e.g availabilityException metadata)
  const { start, end } = timeslot.attributes;

  const hasMembership = currentUserMemberships?.length > 0;
  const membersOnlyTimeslot = access === AVAILABILITY_EXCEPTION_METADATA_ACCESS_MEMBERS;

  const timeslotParams = {
    id: timeslot.id.uuid,
    name: title,
    access,
    start,
    end,
    meeting,
  };

  const handleJoinTimeslot = () => {
    if (membersOnlyTimeslot && !hasMembership) {
      // Save timeslot to the ExperiencePage state and
      // open membership modal.
      onPaidBooking({ timeslot: timeslotParams });
    } else {
      onBooking({ timeslot: timeslotParams });
    }
  };

  const renderStartDate = (
    <TimeRange startDate={start} endDate={end} dateType="date" timeZone={timeZone} />
  );
  const renderTimes = (
    <TimeRange startDate={start} endDate={end} dateType="time" timeZone={timeZone} />
  );

  const timeslotDisabled = isOwnListing;
  return (
    <div key={timeslot.id.uuid} className={css.timeslot}>
      <div className={css.timeslotInfo}>
        <span className={css.timeslotTitle}>
          {membersOnlyTimeslot ? <IconPremium className={css.premiumIcon} /> : null}
          {title ? title : renderStartDate}
        </span>
        <span className={css.timeslotDates}>
          {renderStartDate} · {renderTimes}
        </span>
      </div>
      <Button
        className={css.timeslotButton}
        type="button"
        onClick={handleJoinTimeslot}
        disabled={timeslotDisabled}
      >
        <FormattedMessage id="BookingPanel.join" />
      </Button>
    </div>
  );
};

const PanelTimeslots = props => {
  // Maintain visible timeslots that currently renders
  // three timeslots at once, and allows users to see
  // more by clicking a button.
  const [maxVisibleTimeslots, setMaxVisibleTimeslots] = useState(MAX_VISIBLE_TIMESLOTS);

  const { timeslots, timeslotsMetadata, fetchInProgress, fetchError, ...rest } = props;
  const timeslotCount = timeslots ? timeslots.length : 0;

  if (fetchInProgress) {
    return (
      <div className={css.panelTimeslots}>
        <IconSpinner className={css.spinner} />
      </div>
    );
  } else if (fetchError) {
    return (
      <div className={css.panelTimeslots}>
        <p className={css.error}>
          <FormattedMessage id="BookingPanel.fetchError" />
        </p>
      </div>
    );
  } else if (timeslotCount === 0) {
    return (
      <div className={css.panelTimeslots}>
        <p className={css.noTimeslots}>
          <FormattedMessage id="BookingPanel.noTimeslots" />
        </p>
      </div>
    );
  }

  return (
    <div className={css.panelTimeslots}>
      <div className={css.timeslots}>
        {visibleTimeslots(timeslots, maxVisibleTimeslots).map(t => {
          const timeslotMetadata = timeslotsMetadata?.find(
            metadata =>
              metadata.id === t.id.uuid ||
              metadata.attributes?.repeatingExceptionIds?.includes(t.id.uuid)
          );
          return (
            <Timeslot
              key={t.id.uuid}
              timeslot={t}
              timeslotMetadata={timeslotMetadata}
              timeZone={defaultTimeZone()}
              {...rest}
            />
          );
        })}
      </div>
      {timeslots.length > maxVisibleTimeslots ? (
        <SecondaryButton
          className={css.showMoreTimeslotsButton}
          onClick={() => setMaxVisibleTimeslots(timeslots.length)}
        >
          <FormattedMessage id="BookingPanel.showMoreTimeslots" />
        </SecondaryButton>
      ) : null}
    </div>
  );
};

export default PanelTimeslots;
