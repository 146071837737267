import React from 'react';

const MailingIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="172"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 48 48"
    >
      <path fill="#fd5d73" d="M2 12.01h39.326v28.656H2z"></path>
      <path
        stroke="#4a4a4a"
        strokeLinejoin="round"
        d="M2 12.01h39.326v28.656H2zM6.573 7.334H46l-4.674 4.674H2l4.573-4.674z"
      ></path>
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.58 7.334l-4.572 4.674M22.055 7.334l-4.573 4.674M29.528 7.334l-4.573 4.674M37.325 7.334l-4.573 4.674"
      ></path>
      <path
        stroke="#4a4a4a"
        strokeLinejoin="round"
        d="M45.999 7.334V35.99l-4.675 4.674V12.008L46 7.334z"
      ></path>
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M45.999 14.854l-4.675 4.674M45.999 21.559l-4.675 4.674M45.999 28.266l-4.675 4.674"
      ></path>
      <path
        stroke="#4a4a4a"
        strokeLinejoin="round"
        d="M2 15.603V12.01h39.326v3.593L21.663 28.268 2 15.603z"
      ></path>
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.652 23.086v3.246M8.275 24.709H5.03M15.857 28.98v3.247M17.48 30.604h-3.246M9.604 34.185v3.247M11.227 35.809H7.98M24.955 34.185v3.247M26.578 35.809h-3.246M36.326 23.254V26.5M37.95 24.877h-3.247M29.992 28.27v3.246M31.615 29.893H28.37M36.326 33.852v3.246M37.95 35.475h-3.247"
      ></path>
    </svg>
  );
};

export default MailingIcon;
