import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './RecommendedSizes.module.css';

const RecommendedSizes = props => {
  const { rootClassName, className, sizeItems } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.heading}>
        <FormattedMessage id="RecommendedSizes.heading" />
      </h2>
      <ul className={css.list}>
        {sizeItems.map(s => {
          return (
            <li key={s.key} className={css.listItem}>
              {s.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RecommendedSizes.defaultProps = {
  rootClassName: null,
  className: null,
  sizeItems: [],
};

RecommendedSizes.propTypes = {
  rootClassName: string,
  className: string,
  sizeItems: arrayOf(
    shape({
      key: string.isRequired,
      text: string.isRequired,
    })
  ),
};

export default RecommendedSizes;
