import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVideo.module.css';

const IconVideo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <g fill="none" strokeLinecap="round" strokeLinejoin="round">
        <rect x="0.5" y="0.5" width="13" height="13" rx="1" />
        <path d="M5.49 10.56V6.73A.36.36 0 0 1 6 6.42l3.32 1.91a.37.37 0 0 1 0 .63L6 10.88a.37.37 0 0 1-.51-.32ZM.5 4h13M4 4 5.5.5M8.5 4 10 .5" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconVideo.defaultProps = {
  className: null,
  rootClassName: null,
};

IconVideo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVideo;
