import { v4 as generateUUID } from 'uuid';

/**
 * Fn that gets the passed user's organization profiles.
 *
 * @param {object} user
 *
 * @return {array} form organization profiles
 */
export const getUserOrganizationProfiles = user => {
  const organizationProfiles = user?.attributes?.profile?.publicData?.organizationProfiles;
  return organizationProfiles;
};

/**
 * Fn that denormalises the user organizations from his extended data
 * to native form array of objects.
 *
 * @param {object} user
 *
 * @return {array} form organization profiles
 */
export const getFormUserOrganizationProfiles = user => {
  const organizationProfiles = user?.attributes?.profile?.publicData?.organizationProfiles;
  const organizationProfilesForForm = organizationProfiles
    ? organizationProfiles.map(p => ({
        key: p.id,
        label: p.name,
      }))
    : [];

  return organizationProfilesForForm;
};

/**
 * Fn that prepares the organization profiles before they're saved in
 * user extended data.
 *
 * @param {array} organizationProfiles
 *
 * @returns {Promise<array>} updated organization profiles
 */
export const prepareUpdatedOrganizationProfiles = async (organizationProfiles, onUploadImage) => {
  const filteredOrganizationProfiles = organizationProfiles?.filter(
    p => p !== null && typeof p !== 'undefined'
  );

  const preparedOrganizationProfiles = filteredOrganizationProfiles?.map(async p => {
    const profileImage = p.profileImage;
    const uploadedImage = profileImage?.lastModified
      ? await onUploadImage(profileImage)
      : profileImage;

    return profileImage && uploadedImage
      ? {
          ...p,
          id: p.id ? p.id : generateUUID(),
          profileImage: uploadedImage,
        }
      : {
          ...p,
          id: p.id ? p.id : generateUUID(),
        };
  });

  const promisedOrganizationProfiles = await Promise.all(preparedOrganizationProfiles);
  return promisedOrganizationProfiles;
};

/**
 * Fn that creates default organization profile during signup.
 *
 * @param {string} firstName
 *
 * @returns {array} organization profile
 */
export const createDefaultOrganizationProfile = firstName => {
  return [
    {
      id: generateUUID(),
      name: `${firstName} organization`,
    },
  ];
};
