import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Promised, VideoPlayer } from '../../components';
import config from '../../config';

import css from './VideoFromFile.module.css';

const DURATION_ERROR_MSG_ID = 'VideoFromFile.durationTooLong';
const UPLOAD_ERROR_MSD_ID = 'VideoFromFile.couldNotReadFile';

// VideoPlayer settings
const VIDEO_PLAYER_SETTINGS = {
  playerTime: false,
};

const createVideoPreview = file => file && URL.createObjectURL(file);

// readVideo returns a promise which is resolved
// when FileReader has loaded given file as dataURL
const readVideo = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => {
        const duration = media.duration;

        // Throw an error if duration is more then maximum
        // allowed one (e.g 30 sec)
        if (duration > config.videoSettings.maxDuration) {
          // eslint-disable-next-line
          console.error(`${file.name} duration is too long: ${e.target.result}`);
          new Error(`Error reading ${file.name}: ${e.target.result}`);

          reject(DURATION_ERROR_MSG_ID);
        }
        resolve(e.target.result);
      };
    };
    reader.onerror = e => {
      // eslint-disable-next-line
      console.error('Error (', e, `) happened while reading ${file.name}: ${e.target.result}`);
      new Error(`Error reading ${file.name}: ${e.target.result}`);

      reject(UPLOAD_ERROR_MSD_ID);
    };
    reader.readAsDataURL(file);
  });

const RejectedFileComponent = props => {
  const { video, errorMsgId } = props;
  return (
    <div className={css.error}>
      <div className={css.errorVideo}>
        <VideoPlayer
          className={css.videoPlayer}
          videoWrapperClassName={css.videoPlayerAspectWrapper}
          videoClassName={css.video}
          video={video}
          settings={{ ...VIDEO_PLAYER_SETTINGS, disabled: true }}
        />
        <div className={css.errorOverlay} />
      </div>
      <p className={css.errorMessage}>
        <FormattedMessage id={errorMsgId} values={{ duration: config.videoSettings.maxDuration }} />
      </p>
    </div>
  );
};

// Create elements out of given thumbnail file
class VideoFromFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promisedVideo: readVideo(this.props.file),
    };
  }

  render() {
    const { className, rootClassName, file, id, disabled, children } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    // Video preview which helps reading the file even before
    // we receive dataURL from the FileReader promise
    const srcPreview = createVideoPreview(file);
    return (
      <Promised
        key={id}
        promise={this.state.promisedVideo}
        renderFulfilled={dataURL => {
          const videoSrc = dataURL || srcPreview;
          return (
            <div className={classes}>
              <VideoPlayer
                className={css.videoPlayer}
                videoWrapperClassName={css.videoPlayerAspectWrapper}
                videoClassName={css.video}
                video={{ id, src: videoSrc }}
                settings={{ ...VIDEO_PLAYER_SETTINGS, disabled }}
              />
              {children}
            </div>
          );
        }}
        renderRejected={errorMsgId => {
          return <RejectedFileComponent video={{ id, src: srcPreview }} errorMsgId={errorMsgId} />;
        }}
      />
    );
  }
}

VideoFromFile.defaultProps = {
  className: null,
  rootClassName: null,
  disabled: false,
  children: null,
};

const { any, node, string, bool } = PropTypes;

VideoFromFile.propTypes = {
  className: string,
  rootClassName: string,
  file: any.isRequired,
  id: string.isRequired,
  disabled: bool.isRequired,
  children: node,
};

export default VideoFromFile;
