import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryButton, Modal, NamedLink } from '../../components';
import { BetaNewsletterForm } from '../../forms';
import classNames from 'classnames';

import css from './SectionJoin.module.css';

const SectionJoin = props => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const {
    rootClassName,
    className,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(css.root, rootClassName, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionJoin.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionJoin.subTitle" />
        </p>
        <div className={css.buttons}>
          <SecondaryButton type="button" className={css.button} onClick={() => setModalOpen(true)}>
            <FormattedMessage id="SectionJoin.buttonFirstText" />
          </SecondaryButton>
          <NamedLink name="BecomeCreatorPage" className={css.button}>
            <FormattedMessage id="SectionJoin.buttonSecondText" />
          </NamedLink>
        </div>
      </div>
      <Modal
        id="SectionJoin.modal"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {isSubmitted && !subscribeToListError ? (
          <>
            <h2 className={css.modalTitle}>
              <FormattedMessage id="SectionJoin.modalSubmittedTitle" />
            </h2>
            <p className={css.modalSubTitle}>
              <FormattedMessage id="SectionJoin.modalSubmittedSubTitle" />
            </p>
          </>
        ) : (
          <>
            <h2 className={css.modalTitle}>
              <FormattedMessage id="SectionJoin.modalTitle" />
            </h2>
            <p className={css.modalSubTitle}>
              <FormattedMessage id="SectionJoin.modalSubTitle" />
            </p>
            <BetaNewsletterForm
              isSubmitted={isSubmitted}
              inProgress={subscribeToListInProgress}
              subscribeToListError={subscribeToListError}
              onSubmit={({ email }) => {
                const subscribingUser = {
                  email,
                };
                setSubmitted(false);
                onSubscribeToList({
                  listId: process.env.REACT_APP_MAILCHIMP_NEWSLETTER_LIST_ID,
                  subscribingUser,
                }).then(() => setSubmitted(true));
              }}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

SectionJoin.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionJoin.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionJoin;
