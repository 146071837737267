import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

import css from './ShareModal.module.css';

const SHARE_ICON_SIZE = 20;
const CANONICAL_ROOT_URL = process.env.REACT_APP_CANONICAL_ROOT_URL;

const ShareButtons = props => {
  const { location } = props;

  // react-share button props
  const reactShareButtonProps = {
    url: `${CANONICAL_ROOT_URL}/${location.pathname}`,
    resetButtonStyle: false,
  };

  // react-share icon props
  const reactShareIconProps = {
    className: css.shareIcon,
    size: SHARE_ICON_SIZE,
    round: true,
  };

  // Share buttons
  const facebookButton = (
    <FacebookShareButton className={css.facebookButton} {...reactShareButtonProps}>
      <FacebookIcon {...reactShareIconProps} />
      <FormattedMessage id="ShareModal.shareFacebook" />
    </FacebookShareButton>
  );
  const twitterButton = (
    <TwitterShareButton className={css.twitterButton} {...reactShareButtonProps}>
      <TwitterIcon {...reactShareIconProps} />
      <FormattedMessage id="ShareModal.shareTwitter" />
    </TwitterShareButton>
  );
  const whatsappButton = (
    <WhatsappShareButton className={css.whatsappButton} {...reactShareButtonProps}>
      <WhatsappIcon {...reactShareIconProps} />
      <FormattedMessage id="ShareModal.shareWhatsapp" />
    </WhatsappShareButton>
  );
  const emailButton = (
    <EmailShareButton className={css.emailButton} {...reactShareButtonProps}>
      <EmailIcon {...reactShareIconProps} />
      <FormattedMessage id="ShareModal.shareEmail" />
    </EmailShareButton>
  );
  return (
    <div className={css.shareButtons}>
      {facebookButton}
      {twitterButton}
      {whatsappButton}
      {emailButton}
    </div>
  );
};

export default ShareButtons;
