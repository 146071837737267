import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageSubscriptionsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageSubscriptionsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageSubscriptionsPage/FETCH_LISTINGS_ERROR';

export const FETCH_SUBSCRIPTIONS_REQUEST =
  'app/ManageSubscriptionsPage/FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS =
  'app/ManageSubscriptionsPage/FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'app/ManageSubscriptionsPage/FETCH_SUBSCRIPTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListingsInProgress: false,
  fetchListingsError: null,
  listingIds: [],
  fetchSubscriptionsInProgress: false,
  fetchSubscriptionsError: null,
  subscriptions: [],
};

const resultIds = data => data.data.map(l => l.id);

const manageSubscriptionsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    case FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        fetchSubscriptionsInProgress: true,
        fetchSubscriptionsError: null,
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        fetchSubscriptionsInProgress: false,
        fetchSubscriptionsError: null,
        subscriptions: payload.subscriptions,
      };
    case FETCH_SUBSCRIPTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchSubscriptionsInProgress: false, fetchSubscriptionsError: payload };

    default:
      return state;
  }
};

export default manageSubscriptionsPageReducer;

// ================ Action creators ================ //

export const fetchListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionsRequest = () => ({
  type: FETCH_SUBSCRIPTIONS_REQUEST,
});

export const fetchSubscriptionsSuccess = subscriptions => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: { subscriptions },
});

export const fetchSubscriptionsError = e => ({
  type: FETCH_SUBSCRIPTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchListings = params => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest(params));

  return sdk.listings
    .query({
      ...params,
      include: ['images', 'author'],
      'fields.image': [
        'variants.square-small',
        'variants.landscape-crop',
        'variants.landscape-crop2x',
      ],
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};

export const fetchSubscriptions = () => (dispatch, getState, sdk) => {
  dispatch(fetchSubscriptionsRequest());

  return sdk.currentUser
    .show()
    .then(response => {
      const currentUser = response.data.data;

      const subscriptions = currentUser.attributes.profile.metadata.memberships || [];
      const subscriptionListingIds = subscriptions.map(m => m.listingId);

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchSubscriptionsSuccess(subscriptions));
      return subscriptionListingIds;
    })
    .catch(e => dispatch(fetchSubscriptionsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  return dispatch(fetchSubscriptions()).then(subscriptionListingIds => {
    const queryParams = {
      ids: subscriptionListingIds,
    };
    dispatch(fetchListings(queryParams));
  });
};
