import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const InboxIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <g clipPath="url(#a)" strokeWidth="1.714" strokeLinecap="round" strokeLinejoin="round">
        <path d="M17.857.714H2.143c-.79 0-1.429.64-1.429 1.429v15.714c0 .79.64 1.429 1.429 1.429h15.714c.79 0 1.429-.64 1.429-1.429V2.143c0-.79-.64-1.429-1.429-1.429Z" />
        <path d="M.714 11.429h5a1.429 1.429 0 0 1 1.429 1.428 2.857 2.857 0 1 0 5.714 0 1.429 1.429 0 0 1 1.429-1.428h5" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

InboxIcon.defaultProps = {
  className: null,
};

InboxIcon.propTypes = {
  className: string,
};

export default InboxIcon;
