import React from 'react';

const HeartIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 14 14"
    >
      <g fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6 11L5 13.5"></path>
        <path d="M8 11L9 13.5"></path>
        <path d="M4 13.5L10 13.5"></path>
        <path d="M13 2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-8A.5.5 0 011 2"></path>
        <path d="M7 4c1.17-2.59 3.5-1.29 3.5.65C10.5 7.2 7 8.5 7 8.5S3.5 7.2 3.5 4.6C3.5 2.66 5.83 1.36 7 4z"></path>
      </g>
    </svg>
  );
};

export default HeartIcon;
