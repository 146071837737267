import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMagicStick.module.css';

const IconMagicStick = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_103_90)">
        <path
          d="M21.6686 3.20572L20.2286 7.45715C20.1789 7.55548 20.153 7.66411 20.153 7.77429C20.153 7.88447 20.1789 7.99311 20.2286 8.09144L22.92 11.6914C22.9974 11.7945 23.0441 11.9172 23.0549 12.0456C23.0657 12.174 23.0401 12.3028 22.9811 12.4173C22.922 12.5319 22.8319 12.6274 22.7211 12.6931C22.6102 12.7587 22.4831 12.7918 22.3543 12.7886H17.8629C17.7528 12.7918 17.6448 12.8197 17.5469 12.8701C17.449 12.9205 17.3637 12.9923 17.2972 13.08L14.7943 16.68C14.7209 16.7852 14.6194 16.8678 14.5014 16.9182C14.3835 16.9686 14.2537 16.985 14.1269 16.9653C14.0001 16.9457 13.8814 16.8909 13.7842 16.8071C13.687 16.7233 13.6153 16.6139 13.5772 16.4914L12.24 12.2057C12.2095 12.0998 12.1526 12.0033 12.0746 11.9254C11.9967 11.8474 11.9002 11.7905 11.7943 11.76L7.50858 10.4229C7.38607 10.3847 7.27671 10.313 7.19294 10.2158C7.10916 10.1186 7.05434 9.9999 7.0347 9.87311C7.01505 9.74631 7.03138 9.61655 7.08181 9.49857C7.13225 9.38059 7.21478 9.27913 7.32001 9.20572L10.9886 6.61715C11.0763 6.55061 11.1481 6.46526 11.1985 6.36736C11.2489 6.26946 11.2768 6.16151 11.28 6.05144V1.56001C11.2848 1.44204 11.32 1.3273 11.3821 1.22691C11.4442 1.12652 11.5312 1.04388 11.6347 0.98698C11.7382 0.930082 11.8545 0.900859 11.9726 0.90214C12.0907 0.903421 12.2064 0.935164 12.3086 0.994294L15.9086 3.68572C16 3.7492 16.1051 3.79007 16.2154 3.80497C16.3257 3.81987 16.4379 3.80838 16.5429 3.77144L20.7943 2.33144C20.9162 2.28859 21.0478 2.28106 21.1738 2.30973C21.2998 2.33841 21.4151 2.4021 21.5065 2.49349C21.5979 2.58487 21.6616 2.70022 21.6903 2.82623C21.719 2.95225 21.7114 3.0838 21.6686 3.20572V3.20572Z"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0686 11.9315L0.857147 23.1429"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_90">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconMagicStick.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMagicStick.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMagicStick;
