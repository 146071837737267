import React, { useEffect, useState } from 'react';
import { string, bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { BetaSignupForm } from '../../forms';
import classNames from 'classnames';

import css from './SectionCreatorHero.module.css';

// Extracted function to handle Mailchimp errors
const mailchimpErrorMessage = (intl, error) => {
  if (error.name === 'Member Exists') {
    return intl.formatMessage({ id: 'SectionCreatorHero.errorMemberExist' });
  }

  return intl.formatMessage({ id: 'SectionCreatorHero.error' });
};

const SectionCreatorHero = props => {
  const [mounted, setMounted] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const {
    rootClassName,
    className,
    intl,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    isWithListingsLayout,
  } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  // Simplified function to handle form submission
  const handleSubmit = (values, form) => {
    const { email } = values;

    const subscribingUser = {
      email,
    };

    onSubscribeToList({
      listId: process.env.REACT_APP_MAILCHIMP_CREATOR_LIST_ID,
      subscribingUser,
    }).then(() => {
      form.reset();
      setSubmitted(true);
    });
  };

  const betaSignupContent = () => {
    if (subscribeToListError) {
      return <p className={css.error}>{mailchimpErrorMessage(intl, subscribeToListError)}</p>;
    } else if (isSubmitted) {
      return (
        <p className={css.submittedText}>
          <FormattedMessage id="SectionCreatorHero.submittedText" />
        </p>
      );
    }

    return (
      <BetaSignupForm
        className={css.signupForm}
        onSubmit={handleSubmit}
        inProgress={subscribeToListInProgress}
      />
    );
  };

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithListings]: isWithListingsLayout,
  });

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionCreatorHero.title" />
        </h1>
        <p className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionCreatorHero.subTitle" />
        </p>
        {betaSignupContent()}
      </div>
    </div>
  );
};

SectionCreatorHero.defaultProps = {
  rootClassName: null,
  className: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,

  isWithListingsLayout: false,
};

SectionCreatorHero.propTypes = {
  rootClassName: string,
  className: string,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,

  isWithListingsLayout: bool.isRequired,
};

export default SectionCreatorHero;
