import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPlay.module.css';

const STYLE_OUTLINE = 'outline';

const IconPlay = props => {
  const { className, rootClassName, style } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isOutline = style === STYLE_OUTLINE;

  if (isOutline) {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 14 14"
      >
        <g fill="none" stroke="#4a4a4a" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="7" cy="7" r="6.5"></circle>
          <path d="M5.5 4.5L9.5 7 5.5 9.5 5.5 4.5z"></path>
        </g>
      </svg>
    );
  }

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <path
        d="M3.429 28.229a2.606 2.606 0 0 0 1.44 2.285 2.835 2.835 0 0 0 2.788 0L27.43 18.286a2.537 2.537 0 0 0 0-4.572L7.657 1.577a2.834 2.834 0 0 0-2.788 0 2.606 2.606 0 0 0-1.44 2.286v24.366Z"
        fill="#fff"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconPlay.defaultProps = {
  className: null,
  rootClassName: null,
  style: null,
};

IconPlay.propTypes = {
  className: string,
  rootClassName: string,
  style: string,
};

export default IconPlay;
