import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import moment from 'moment';

import css from './PaymentBreakdown.module.css';

const BookingPeriod = props => {
  const { bookingDates } = props;
  const { bookingStart, bookingEnd } = bookingDates;

  // Format booking start & end time (e.g Mon, 8:00 PM)
  const formattedBookingStartTime = moment(bookingStart).format('ddd, hh:mm A');
  const formattedBookingEndTime = moment(bookingEnd).format('ddd, hh:mm A');

  // Format booking start & end date (e.g Jan 1)
  const formattedBookingStartDate = moment(bookingStart).format('MMM DD');
  const formattedBookingEndDate = moment(bookingEnd).format('MMM DD');

  return (
    <div className={css.lineItems}>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{formattedBookingStartTime}</span>
        <span className={css.itemValue}>{formattedBookingEndTime}</span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{formattedBookingStartDate}</span>
        <span className={css.itemValue}>{formattedBookingEndDate}</span>
      </div>
    </div>
  );
};

const LineItemBookingDetails = props => {
  const { bookingDates } = props;

  if (!bookingDates?.bookingStart && !bookingDates?.bookingEnd) {
    return null;
  }

  return (
    <div className={css.details}>
      <h4 className={css.detailsTitle}>
        <FormattedMessage id="PaymentBreakdown.bookingDetails" />
      </h4>
      <BookingPeriod bookingDates={bookingDates} />
    </div>
  );
};

LineItemBookingDetails.propTypes = {
  bookingDates: object,
};

export default LineItemBookingDetails;
