import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const AudiencesIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <g clipPath="url(#a)" strokeWidth="1.714" strokeLinecap="round" strokeLinejoin="round">
        <path d="M2.143 3.571A1.429 1.429 0 0 0 .714 5v12.857a1.429 1.429 0 0 0 1.429 1.429h15.714a1.429 1.429 0 0 0 1.429-1.429V5a1.429 1.429 0 0 0-1.429-1.429H15M.714 9.286h18.572M5 .714V6.43M15 .714V6.43M5 3.571h7.143" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

AudiencesIcon.defaultProps = {
  className: null,
};

AudiencesIcon.propTypes = {
  className: string,
};

export default AudiencesIcon;
