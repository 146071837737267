import { mailchimpAPI } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const SUBSCRIBE_TO_LIST_REQUEST = 'app/assets/SUBSCRIBE_TO_LIST_REQUEST';
export const SUBSCRIBE_TO_LIST_SUCCESS = 'app/assets/SUBSCRIBE_TO_LIST_SUCCESS';
export const SUBSCRIBE_TO_LIST_ERROR = 'app/assets/SUBSCRIBE_TO_LIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  subscribeToListInProgress: false,
  subscribeToListError: null,
};

export default function mailchimpReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIBE_TO_LIST_REQUEST:
      return { ...state, subscribeToListInProgress: true, subscribeToListError: null };
    case SUBSCRIBE_TO_LIST_SUCCESS:
      return {
        ...state,
        subscribeToListInProgress: false,
        subscribeToListError: null,
      };
    case SUBSCRIBE_TO_LIST_ERROR:
      return { ...state, subscribeToListInProgress: false, subscribeToListError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const subscribeToListRequest = () => ({ type: SUBSCRIBE_TO_LIST_REQUEST });
export const subscribeToListSuccess = () => ({
  type: SUBSCRIBE_TO_LIST_SUCCESS,
});
export const subscribeToListError = error => ({
  type: SUBSCRIBE_TO_LIST_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const subscribeToList = bodyParams => (dispatch, getState, sdk) => {
  dispatch(subscribeToListRequest());

  return mailchimpAPI
    .subscribe(bodyParams)
    .then(response => {
      dispatch(subscribeToListSuccess(response));
      return response;
    })
    .catch(e => dispatch(subscribeToListError(storableError(e))));
};
