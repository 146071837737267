import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconRestriction.module.css';

const IconRestriction = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_103_71)">
        <path
          d="M8.57144 8.5714C10.7017 8.5714 12.4286 6.8445 12.4286 4.71426C12.4286 2.58402 10.7017 0.857117 8.57144 0.857117C6.4412 0.857117 4.71429 2.58402 4.71429 4.71426C4.71429 6.8445 6.4412 8.5714 8.57144 8.5714Z"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5714 23.1429C20.6484 23.1429 23.1429 20.6484 23.1429 17.5714C23.1429 14.4944 20.6484 12 17.5714 12C14.4944 12 12 14.4944 12 17.5714C12 20.6484 14.4944 23.1429 17.5714 23.1429Z"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6286 21.5143L21.5143 13.6285"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2857 11.3314C9.1553 11.0705 7.98052 11.0684 6.8492 11.3254C5.71787 11.5824 4.65928 12.0918 3.75255 12.8155C2.84582 13.5392 2.11443 14.4586 1.61304 15.5048C1.11165 16.551 0.853249 17.697 0.857145 18.8571V21.4286H7.71429"
          stroke="#4A4A4A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_71">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconRestriction.defaultProps = {
  className: null,
  rootClassName: null,
};

IconRestriction.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRestriction;
