import React from 'react';
import PropTypes from 'prop-types';
import { LinkTabNavHorizontal } from '..';
import classNames from 'classnames';

import css from './DashboardNav.module.css';

const DashboardNav = props => {
  const { className, rootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <LinkTabNavHorizontal
      className={classes}
      tabs={tabs}
      tabContentClassName={css.tabContent}
      selectedTabContentClassName={css.selectedTabContent}
    />
  );
};

DashboardNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

DashboardNav.propTypes = {
  className: string,
  rootClassName: string,
};

export default DashboardNav;
