import React from 'react';
import { string, node } from 'prop-types';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './Table.module.css';

const TableRowItem = props => {
  const { rootClassName, className, children } = props;
  const classes = classNames(rootClassName || css.rowItem, className);

  return <td className={classes}>{children}</td>;
};

TableRowItem.defaultProps = {
  rootClassName: null,
  className: null,
};

TableRowItem.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
};

export default TableRowItem;
