import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';

import circusImage from './images/circus.jpg';
import playImage from './images/play.jpg';
import djImage from './images/dj.jpg';
import musicalImage from './images/musical.jpg';
import danceImage from './images/dance.jpg';
import artsImage from './images/arts.png';

import css from './SectionCategories.module.css';

const categories = [
  {
    key: 'circus',
    label: 'Circus',
    imageSrc: circusImage,
  },
  { key: 'play', label: 'Play', imageSrc: playImage },
  { key: 'dance', label: 'Dance', imageSrc: danceImage },
  { key: 'musical', label: 'Musical', imageSrc: musicalImage },
  { key: 'dj', label: 'DJ', imageSrc: djImage },
  {
    key: 'traditional-performing-arts',
    label: 'Traditional performing arts',
    imageSrc: artsImage,
  },
];

const SectionCategories = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionCategories.title" values={{ siteTitle: config.siteTitle }} />
      </div>
      <div className={css.categories}>
        {categories.map(c => {
          return (
            <div
              key={c.key}
              className={css.category}
              style={{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${c.imageSrc})`,
              }}
            >
              {c.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

SectionCategories.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
