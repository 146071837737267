import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, UserDisplayName, ExpandableBio, Slider } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const EXPANDABLE_BIO_LENGTH = 160;

// slidesToShow
const REVIEW_SLIDES_TO_SHOW_TABLET = 3;
const REVIEW_SLIDES_TO_SHOW_DESKTOP = 5;
// slidesToScroll
const REVIEW_SLIDES_TO_SCROLL_TABLET = 1;
const REVIEW_SLIDES_TO_SCROLL_DESKTOP = 1;

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <div className={css.reviewHeader}>
        <Avatar className={css.avatar} user={review.author} disableProfileLink />
        <div>
          <p className={css.reviewInfo}>
            <UserDisplayName className={css.userDisplayName} user={review.author} intl={intl} />
            <span className={css.reviewDate}>{dateString}</span>
          </p>
        </div>
      </div>
      <ExpandableBio
        className={css.reviewContent}
        bio={review.attributes.content}
        maxLength={EXPANDABLE_BIO_LENGTH}
      />
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const MobileReviews = props => {
  const { reviews, intl } = props;
  const sliderTitle = intl.formatMessage({ id: 'Reviews.mobileTitle' });
  return (
    <Slider
      id="ReviewsSlider"
      headerClassName={css.mobileReviewsHeader}
      titleClassName={css.mobileReviewsTitle}
      title={sliderTitle}
      slidesToShow={{
        tablet: REVIEW_SLIDES_TO_SHOW_TABLET,
        desktop: REVIEW_SLIDES_TO_SHOW_DESKTOP,
      }}
      slidesToScroll={{
        tablet: REVIEW_SLIDES_TO_SCROLL_TABLET,
        desktop: REVIEW_SLIDES_TO_SCROLL_DESKTOP,
      }}
    >
      {reviews.map(r => {
        return <Review key={r.id} review={r} intl={intl} />;
      })}
    </Slider>
  );
};

const ReviewsComponent = props => {
  const {
    className,
    rootClassName,
    reviewsClassName,
    mobileReviewsClassName,
    reviews,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={classNames(css.mobileReviews, mobileReviewsClassName)}>
        <MobileReviews reviews={reviews} intl={intl} />
      </div>
      <ul className={classNames(css.reviews, reviewsClassName)}>
        {reviews.map(r => {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviewsClassName: null,
  mobileReviewsClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviewsClassName: string,
  mobileReviewsClassName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
