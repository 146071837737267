import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
} from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// When we're querying experiences (e.g queryExperiences thunk func),
// we are only interested in one result which will determine if the
// listing (e.g show) has any experience connected with it.
//
// We will use a different thunk function to later on query and
// allow searching/filtering the experiences on the listing page.
const QUERY_EXPERIENCES_RESULT_PAGE_SIZE = 1;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const QUERY_USERS_REQUEST = 'app/LandingPage/QUERY_USERS_REQUEST';
export const QUERY_USERS_SUCCESS = 'app/LandingPage/QUERY_USERS_SUCCESS';
export const QUERY_USERS_ERROR = 'app/LandingPage/QUERY_USERS_ERROR';

export const QUERY_EXPERIENCES_REQUEST = 'app/LandingPage/QUERY_EXPERIENCES_REQUEST';
export const QUERY_EXPERIENCES_SUCCESS = 'app/LandingPage/QUERY_EXPERIENCES_SUCCESS';
export const QUERY_EXPERIENCES_ERROR = 'app/LandingPage/QUERY_EXPERIENCES_ERROR';

export const SEARCH_EXPERIENCES_REQUEST = 'app/LandingPage/SEARCH_EXPERIENCES_REQUEST';
export const SEARCH_EXPERIENCES_SUCCESS = 'app/LandingPage/SEARCH_EXPERIENCES_SUCCESS';
export const SEARCH_EXPERIENCES_ERROR = 'app/LandingPage/SEARCH_EXPERIENCES_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingInProgress: false,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  userIds: [],
  queryUsersInProgress: false,
  queryUsersError: null,
  experienceIds: [],
  hasExperience: false,
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
  searchExperienceIds: [],
  searchExperiencesInProgress: false,
  searchExperiencesError: null,
};

const resultIds = data => data.data.map(d => d.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingInProgress: true, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingInProgress: false, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingInProgress: false, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case QUERY_USERS_REQUEST:
      return {
        ...state,
        queryUsersInProgress: true,
        queryUsersError: null,
      };
    case QUERY_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload.data),
        queryUsersInProgress: false,
        queryUsersError: null,
      };
    case QUERY_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: false,
        queryUsersError: payload,
      };

    case QUERY_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryExperiencesInProgress: true,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_SUCCESS:
      const experienceIds = resultIds(payload.data);
      const hasExperience = experienceIds.length > 0;
      return {
        ...state,
        experienceIds,
        hasExperience,
        queryExperiencesInProgress: false,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_ERROR:
      return {
        ...state,
        experienceIds: [],
        queryExperiencesInProgress: false,
        queryExperiencesError: payload,
      };

    case SEARCH_EXPERIENCES_REQUEST:
      return {
        ...state,
        searchExperiencesInProgress: true,
        searchExperiencesError: null,
      };
    case SEARCH_EXPERIENCES_SUCCESS:
      return {
        ...state,
        searchExperienceIds: resultIds(payload.data),
        searchExperiencesInProgress: false,
        searchExperiencesError: null,
      };
    case SEARCH_EXPERIENCES_ERROR:
      return {
        ...state,
        searchExperienceIds: [],
        searchExperiencesInProgress: true,
        searchExperiencesError: null,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = () => ({ type: SHOW_LISTING_SUCCESS });
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const queryUsersRequest = () => ({
  type: QUERY_USERS_REQUEST,
});
export const queryUsersSuccess = response => ({
  type: QUERY_USERS_SUCCESS,
  payload: { data: response.data },
});
export const queryUsersError = e => ({
  type: QUERY_USERS_ERROR,
  error: true,
  payload: e,
});

export const queryExperiencesRequest = () => ({
  type: QUERY_EXPERIENCES_REQUEST,
});
export const queryExperiencesSuccess = response => ({
  type: QUERY_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});
export const queryExperiencesError = e => ({
  type: QUERY_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

export const searchExperiencesRequest = () => ({
  type: SEARCH_EXPERIENCES_REQUEST,
});
export const searchExperiencesSuccess = response => ({
  type: SEARCH_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});
export const searchExperiencesError = e => ({
  type: SEARCH_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
      'variants.hero-scale',
      'variants.hero-crop',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
    'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(showListingSuccess(data));
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const queryUsers = () => async (dispatch, getState, sdk) => {
  dispatch(queryUsersRequest());

  return integrationAPI.users
    .query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryUsersSuccess(response.data));

      return response.data;
    })
    .catch(e => dispatch(queryUsersError(storableError(e))));
};

export const queryExperiences = listingId => (dispatch, getState, sdk) => {
  dispatch(queryExperiencesRequest());

  return sdk.listings
    .query({
      type: config.listingTypes['experience'],
      pub_showId: listingId.uuid,
      per_page: QUERY_EXPERIENCES_RESULT_PAGE_SIZE,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryExperiencesSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryExperiencesError(storableError(e))));
};

export const searchExperiences = (listingId, params) => (dispatch, getState, sdk) => {
  dispatch(searchExperiencesRequest());

  return sdk.listings
    .query({
      ...params,
      type: config.listingTypes['experience'],
      pub_showId: listingId.uuid,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.hero-scale',
        'variants.hero-crop',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
      'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 200,
        h: 240,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 400,
        h: 480,
        fit: 'crop',
      }),
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchExperiencesSuccess(response));
      return response;
    })
    .catch(e => dispatch(searchExperiencesError(storableError(e))));
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);
  const queryParams = parse(search);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return Promise.all([dispatch(showListing(listingId, true)), dispatch(queryUsers())]);
  }

  return Promise.all([
    dispatch(showListing(listingId)),
    dispatch(fetchReviews(listingId)),
    dispatch(searchExperiences(listingId, queryParams)), // Search experiences
    dispatch(queryExperiences(listingId)), // Query single experience (hasExperiences)
    dispatch(queryUsers()),
  ]);
};
