import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bool, node, number, string } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { NamedLink, NamedRedirect } from '..';
import classNames from 'classnames';

import CalendarIcon from './Icons/CalendarIcon';
import StreamIcon from './Icons/StreamIcon';
import InboxIcon from './Icons/InboxIcon';
import AudiencesIcon from './Icons/AudiencesIcon';
import OrganizationIcon from './Icons/OrganizationIcon';
import css from './DashboardWrapper.module.css';

const INBOX_TAB_PAGES = ['InboxPage', 'QuickRepliesPage'];

const DashboardWrapperComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    children,
    currentPage,
    currentUserHasListings,
    providerNotificationCount,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const hasNotifications = providerNotificationCount > 0;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.calendarTabTitle' }),
      selected: currentPage === 'ManageListingsPage',
      id: 'ManageListingsPage',
      icon: <CalendarIcon />,
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.streamTabTitle' }),
      selected: currentPage === 'StreamPage',
      id: 'StreamPageTab',
      icon: <StreamIcon />,
      disabled: !currentUserHasListings,
      linkProps: {
        name: 'StreamPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.inboxTabTitle' }),
      selected: INBOX_TAB_PAGES.includes(currentPage),
      id: 'InboxPageTab',
      icon: hasNotifications ? (
        <span className={css.inboxIconWithNotification}>
          <InboxIcon />
          <span className={css.notificationDot} />
        </span>
      ) : (
        <InboxIcon />
      ),
      disabled: !currentUserHasListings,
      linkProps: {
        name: 'InboxPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.audienceTabTitle' }),
      selected: currentPage === 'AudiencePage',
      id: 'AudiencePageTab',
      icon: <AudiencesIcon />,
      disabled: true,
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.organizationSettingsTabTitle' }),
      selected: currentPage === 'OrganizationSettingsPage' || currentPage === 'PaymentHistoryPage',
      id: 'OrganizationSettingsPageTab',
      icon: <OrganizationIcon />,
      linkProps: {
        name: 'OrganizationSettingsPage',
      },
    },
  ];

  const allowedPages = ['ManageListingsPage', 'OrganizationSettingsPage'];
  if (!currentUserHasListings && !allowedPages.includes(currentPage)) {
    return <NamedRedirect name="ManageListingsPage" />;
  }

  return (
    <div className={classes}>
      <div className={css.sideNav}>
        <div className={css.sideNavItems}>
          {tabs.map(tab => {
            const sideNavItemClasses = classNames(css.sideNavItem, {
              [css.sideNavItemActive]: tab.selected,
              [css.sideNavItemDisabled]: tab.disabled,
            });

            const tabProps = {
              key: tab.id,
              className: sideNavItemClasses,
              title: tab.text,
              ...tab.linkProps,
            };
            return tab.disabled ? (
              <span key={tab.id} className={sideNavItemClasses} title={tab.text}>
                {tab.icon}
              </span>
            ) : (
              <NamedLink {...tabProps}>{tab.icon}</NamedLink>
            );
          })}
        </div>
      </div>
      <div className={css.sideContent}>{children}</div>
    </div>
  );
};

DashboardWrapperComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,

  currentUserHasListings: false,
  providerNotificationCount: 0,
};

DashboardWrapperComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,

  currentUserHasListings: bool.isRequired,
  providerNotificationCount: number,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUserHasListings,
    currentUserNotificationCount: providerNotificationCount,
  } = state.user;

  return {
    currentUserHasListings,
    providerNotificationCount,
  };
};

const DashboardWrapper = compose(
  injectIntl,
  connect(mapStateToProps)
)(DashboardWrapperComponent);

export default DashboardWrapper;
