import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const AudiencesIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.84 12.07L1.17 8a1 1 0 01-.67-.91V6a1 1 0 01.67-.94L12.84 1a.5.5 0 01.66.47V11.6a.5.5 0 01-.66.47zM8.48 10.57A2.75 2.75 0 013 10.25V8.66"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

AudiencesIcon.defaultProps = {
  className: null,
};

AudiencesIcon.propTypes = {
  className: string,
};

export default AudiencesIcon;
