import pick from 'lodash/pick';
import { initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_BOOKING,
  TRANSITION_REQUEST_BOOKING_AFTER_ENQUIRY,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import config from '../../config';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/BookingPage/SET_INITIAL_VALUES';

export const REQUEST_BOOKING_REQUEST = 'app/BookingPage/REQUEST_BOOKING_REQUEST';
export const REQUEST_BOOKING_SUCCESS = 'app/BookingPage/REQUEST_BOOKING_SUCCESS';
export const REQUEST_BOOKING_ERROR = 'app/BookingPage/REQUEST_BOOKING_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  transaction: null,
  requestBookingInProgress: false,
  requestBookingError: null,
};

export default function bookingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case REQUEST_BOOKING_REQUEST:
      return { ...state, requestBookingInProgress: true, requestBookingError: null };
    case REQUEST_BOOKING_SUCCESS:
      return {
        ...state,
        requestBookingInProgress: false,
        requestBookingError: null,
      };
    case REQUEST_BOOKING_ERROR:
      return {
        ...state,
        requestBookingInProgress: false,
        requestBookingError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const requestBookingRequest = () => ({
  type: REQUEST_BOOKING_REQUEST,
});
export const requestBookingSuccess = () => ({
  type: REQUEST_BOOKING_SUCCESS,
});
export const requestBookingError = e => ({
  type: REQUEST_BOOKING_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

export const requestBooking = (bookingParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(requestBookingRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_BOOKING_AFTER_ENQUIRY
    : TRANSITION_REQUEST_BOOKING;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {};

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: bookingParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: bookingParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(requestBookingSuccess(tx));
    return tx;
  };

  const handleError = e => {
    const { listingId } = bookingParams;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
    });
    return dispatch(requestBookingError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};
