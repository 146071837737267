import React, { useRef, useState } from 'react';

// External
import { node, number, string, bool, shape } from 'prop-types';
import classNames from 'classnames';

// Shared
import { withViewport } from '../../util/contextHelpers';
import {
  totalSlidesFn,
  responsiveSettingsFn,
  slideMaxWidthFn,
  SLIDES_TO_SHOW,
  SLIDES_TO_SHOW_TABLET,
  SLIDES_TO_SHOW_DESKTOP,
  SLIDES_TO_SCROLL,
  SLIDES_TO_SCROLL_TABLET,
  SLIDES_TO_SCROLL_DESKTOP,
} from './Slider.helpers';
import Arrows from './Arrows';
import css from './Slider.module.css';

const ENABLE_ARROWS = true;
const DEFAULT_SLIDE_INDEX = 0;

const Slider = props => {
  const {
    className,
    rootClassName,
    headerClassName,
    titleClassName,
    itemsClassName,
    itemClassName,
    arrowsClassName,
    id,
    title,
    arrows,
    slidesToShow,
    slidesToScroll,
    viewport,
    children,
  } = props;

  // Slider refs
  const sliderRef = useRef(null);
  const sliderItemRef = useRef(null);

  // Slider states
  const [slideIndex, setSlideIndex] = useState(DEFAULT_SLIDE_INDEX);

  const totalSlides = totalSlidesFn(children);
  const responsiveSettings = responsiveSettingsFn(slidesToShow, slidesToScroll, viewport, id);

  const showSliderHeader = title || arrows;
  const showArrows = totalSlides > responsiveSettings.slidesToShow && arrows;

  const prevDisabled = slideIndex === DEFAULT_SLIDE_INDEX;
  const nextDisabled = totalSlides - responsiveSettings.slidesToShow <= slideIndex;

  const slideLeft = () => {
    setSlideIndex(prevState => prevState - responsiveSettings.slidesToScroll);

    // Move slider left
    const elementWidth = sliderItemRef.current.offsetWidth * responsiveSettings.slidesToScroll;
    sliderRef.current.scrollLeft = sliderRef.current.scrollLeft - elementWidth;
  };

  const slideRight = () => {
    setSlideIndex(prevState => prevState + responsiveSettings.slidesToScroll);

    // Move slider right
    const elementWidth = sliderItemRef.current.offsetWidth * responsiveSettings.slidesToScroll;
    sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + elementWidth;
  };

  const classes = classNames(rootClassName || css.root, className);
  const headerClasses = classNames(css.sliderHeader, headerClassName);
  const titleClasses = classNames(css.title, titleClassName);
  const itemsClasses = classNames(css.sliderItems, itemsClassName);
  const itemClasses = classNames(css.sliderItem, itemClassName);

  return (
    <div className={classes}>
      {showSliderHeader ? (
        <div className={headerClasses}>
          {title ? <h2 className={titleClasses}>{title}</h2> : null}
          {showArrows ? (
            <Arrows
              className={arrowsClassName}
              onSlidePrev={slideLeft}
              onSlideNext={slideRight}
              prevDisabled={prevDisabled}
              nextDisabled={nextDisabled}
            />
          ) : null}
        </div>
      ) : null}
      <div className={itemsClasses} ref={sliderRef}>
        {React.Children.map(children, child => {
          const element = React.cloneElement(child);
          return (
            <div
              className={itemClasses}
              ref={sliderItemRef}
              style={{
                maxWidth: slideMaxWidthFn(responsiveSettings.slidesToShow, totalSlides, viewport),
              }}
            >
              {element}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Slider.defaultProps = {
  className: null,
  rootClassName: null,
  headerClassName: null,
  titleClassName: null,
  itemsClassName: null,
  itemClassName: null,
  arrowsClassName: null,
  id: null,

  // settings
  title: null,
  arrows: ENABLE_ARROWS,
  slidesToShow: shape({
    mobile: SLIDES_TO_SHOW,
    tablet: SLIDES_TO_SHOW_TABLET,
    desktop: SLIDES_TO_SHOW_DESKTOP,
  }),
  slidesToScroll: shape({
    mobile: SLIDES_TO_SCROLL,
    tablet: SLIDES_TO_SCROLL_TABLET,
    desktop: SLIDES_TO_SCROLL_DESKTOP,
  }),
};

Slider.propTypes = {
  className: string,
  rootClassName: string,
  headerClassName: string,
  titleClassName: string,
  itemsClassName: string,
  itemClassName: string,
  arrowsClassName: string,
  id: string,

  // settings
  title: string,
  arrows: bool,
  slidesToShow: shape({
    mobile: number,
    tablet: number,
    desktop: number,
  }),
  slidesToScroll: shape({
    mobile: number,
    tablet: number,
    desktop: number,
  }),
  children: node,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(Slider);
