import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Logo, NamedLink } from '../../components';
import classNames from 'classnames';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const { className, contentClassName, rootClassName, intl, logoFormat } = props;

  // navigation links
  const logoContainerAndLink = (
    <div className={css.logoContainer}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          className={css.logo}
          format={logoFormat ? logoFormat : 'desktop'}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      <NamedLink className={css.createListingLink} name="BecomeCreatorPage">
        Become a Creator
      </NamedLink>
    </div>
  );

  // classes
  const classes = classNames(rootClassName || css.root, className);
  const contentClasses = classNames(contentClassName, css.content);
  return (
    <nav className={classes}>
      <div className={contentClasses}>{logoContainerAndLink}</div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  contentClassName: null,
  currentUser: null,
  currentPage: null,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  contentClassName: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
