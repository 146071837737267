import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '../../components';
import classNames from 'classnames';

import ShareButtons from './ShareButtons';
import css from './ShareModal.module.css';

const ShareModal = props => {
  const {
    className,
    rootClassName,
    location,
    id,
    isOpen,
    titleMessage,
    onCloseModal,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      removeCloseMessage
    >
      <p className={css.modalTitle}>{titleMessage || <FormattedMessage id="ShareModal.title" />}</p>
      <ShareButtons location={location} />
    </Modal>
  );
};

ShareModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  isOpen: false,
  titleMessage: null,
};

const { string, bool } = PropTypes;

ShareModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  id: string,
  isOpen: bool.isRequired,
  titleMessage: string,
};

export default injectIntl(ShareModal);
