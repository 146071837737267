import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  ExternalLink,
  NamedLink,
} from '../../components';
import config from '../../config';
import classNames from 'classnames';

import css from './Footer.module.css';

const withWidthPages = [
  'ListingPage',
  'ExperiencePage',
  'OrderDetailsPage',
  'AccountSettingsPage',
  'ProfilePage',
];

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, currentPage, intl } = props;

  const socialMediaLinks = renderSocialMediaLinks(intl);
  const isWithWidthPages = [...ACCOUNT_SETTINGS_PAGES, ...withWidthPages].includes(currentPage);

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithWidth]: isWithWidthPages,
  });

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.lists}>
          <div className={css.listWrapper}>
            <ul className={css.list}>
              <li className={css.listItem}>Call to Action</li>
              <li className={css.listItem}>
                <NamedLink name="BecomeCreatorPage" className={css.link}>
                  Become a Creator
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="LandingPage" className={css.link}>
                  Become a brand partner
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="LandingPage" className={css.link}>
                  Become an super fan
                </NamedLink>
              </li>
            </ul>
          </div>
          <div className={css.listWrapper}>
            <ul className={css.list}>
              <li className={css.listItem}>Platform</li>
              <li className={css.listItem}>
                <NamedLink name="CMSPage" params={{ pageId: 'about-us' }} className={css.link}>
                  About
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  Legal
                </NamedLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={css.copyright}>
        <div className={css.copyrightContent}>
          <span className={css.copyrightText}>
            <FormattedMessage id="Footer.copyright" />
          </span>
          <div className={css.socialMediaLinks}>{socialMediaLinks}</div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
