import pick from 'lodash/pick';
import config from '../../config';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { payoutsEnabled } from '../../ducks/stripe.duck.js';

const { UUID } = sdkTypes;

const QUERY_EXPERIENCES_RESULT_PAGE_SIZE = 12;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ExperiencePage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ExperiencePage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ExperiencePage/SHOW_LISTING_ERROR';

export const QUERY_EXPERIENCES_REQUEST = 'app/LandingPage/QUERY_EXPERIENCES_REQUEST';
export const QUERY_EXPERIENCES_SUCCESS = 'app/LandingPage/QUERY_EXPERIENCES_SUCCESS';
export const QUERY_EXPERIENCES_ERROR = 'app/LandingPage/QUERY_EXPERIENCES_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ExperiencePage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ExperiencePage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ExperiencePage/FETCH_REVIEWS_ERROR';

export const FETCH_AVAILABILITY_EXCEPTIONS_REQUEST =
  'app/ExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS =
  'app/ExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_AVAILABILITY_EXCEPTIONS_ERROR =
  'app/ExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS =
  'app/ExperiencePage/FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS';

export const SEND_ENQUIRY_REQUEST = 'app/ExperiencePage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ExperiencePage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ExperiencePage/SEND_ENQUIRY_ERROR';

export const QUERY_USERS_REQUEST = 'app/LandingPage/QUERY_USERS_REQUEST';
export const QUERY_USERS_SUCCESS = 'app/LandingPage/QUERY_USERS_SUCCESS';
export const QUERY_USERS_ERROR = 'app/LandingPage/QUERY_USERS_ERROR';

export const FETCH_AUTHOR_PAYOUTS_ENABLED = 'app/LandingPage/FETCH_AUTHOR_PAYOUTS_ENABLED';

// ================ Reducer ================ //

const initialState = {
  // listing(s)
  id: null,
  showListingError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  experienceIds: [],
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
  // reviews
  reviews: [],
  fetchReviewsError: null,
  // users
  userIds: [],
  queryUsersInProgress: false,
  queryUsersError: null,
  authorPayoutsEnabled: false,
  // exceptions
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  availabilityExceptions: [],
  filteredAvailabilityExceptions: [],
};

const resultIds = data => data.data.map(d => d.id);

const experiencePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case QUERY_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryExperiencesInProgress: true,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_SUCCESS:
      return {
        ...state,
        experienceIds: resultIds(payload.data),
        queryExperiencesInProgress: false,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_ERROR:
      return {
        ...state,
        experienceIds: [],
        queryExperiencesInProgress: false,
        queryExperiencesError: payload,
      };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case QUERY_USERS_REQUEST:
      return {
        ...state,
        queryUsersInProgress: true,
        queryUsersError: null,
      };
    case QUERY_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload.data),
        queryUsersInProgress: false,
        queryUsersError: null,
      };
    case QUERY_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: false,
        queryUsersError: payload,
      };

    case FETCH_AVAILABILITY_EXCEPTIONS_REQUEST:
      return {
        ...state,
        availabilityExceptions: [],
        filteredAvailabilityExceptions: [],
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      };
    case FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: payload,
        filteredAvailabilityExceptions: payload,
        fetchExceptionsError: null,
        fetchExceptionsInProgress: false,
      };
    case FETCH_AVAILABILITY_EXCEPTIONS_ERROR:
      return {
        ...state,
        fetchExceptionsError: payload,
        fetchExceptionsInProgress: false,
      };
    case FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        filteredAvailabilityExceptions: payload,
      };

    case FETCH_AUTHOR_PAYOUTS_ENABLED:
      return {
        ...state,
        authorPayoutsEnabled: payload,
      };

    default:
      return state;
  }
};

export default experiencePageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryExperiencesRequest = () => ({
  type: QUERY_EXPERIENCES_REQUEST,
});
export const queryExperiencesSuccess = response => ({
  type: QUERY_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});
export const queryExperiencesError = e => ({
  type: QUERY_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const queryUsersRequest = () => ({
  type: QUERY_USERS_REQUEST,
});
export const queryUsersSuccess = response => ({
  type: QUERY_USERS_SUCCESS,
  payload: { data: response.data },
});
export const queryUsersError = e => ({
  type: QUERY_USERS_ERROR,
  error: true,
  payload: e,
});

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = params => ({
  type: FETCH_AVAILABILITY_EXCEPTIONS_REQUEST,
  payload: { params },
});
export const fetchAvailabilityExceptionsSuccess = availabilityExceptions => ({
  type: FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS,
  payload: availabilityExceptions,
});
export const fetchAvailabilityExceptionsError = e => ({
  type: FETCH_AVAILABILITY_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

export const filterAvailabilityExceptionsSuccess = availabilityExceptions => ({
  type: FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS,
  payload: availabilityExceptions,
});

export const fetchAuthorPayoutsEnabledSuccess = payoutsEnabled => ({
  type: FETCH_AUTHOR_PAYOUTS_ENABLED,
  payload: payoutsEnabled,
});

// ================ Thunks ================ //

export const fetchAuthorPayoutsEnabled = authorId => (dispatch, getState, sdk) => {
  dispatch(payoutsEnabled(authorId)).then(payoutsEnabled => {
    dispatch(fetchAuthorPayoutsEnabledSuccess(payoutsEnabled));
  });
};

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
      'variants.portrait-crop',
      'variants.portrait-crop2x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'imageVariant.portrait-crop': sdkUtil.objectQueryString({
      w: 200,
      h: 240,
      fit: 'crop',
    }),
    'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
      w: 400,
      h: 480,
      fit: 'crop',
    }),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const denormalisedResponse = denormalisedResponseEntities(data);
      const listing = denormalisedResponse[0];

      dispatch(fetchAuthorPayoutsEnabled(listing.author.id.uuid));
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const queryExperiences = () => (dispatch, getState, sdk) => {
  dispatch(queryExperiencesRequest());

  return sdk.listings
    .query({
      pub_type: config.listingTypes['experience'],
      per_page: QUERY_EXPERIENCES_RESULT_PAGE_SIZE,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.portrait-crop',
        'variants.portrait-crop2x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 200,
        h: 240,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 400,
        h: 480,
        fit: 'crop',
      }),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryExperiencesSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryExperiencesError(storableError(e))));
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const queryUsers = () => async (dispatch, getState, sdk) => {
  dispatch(queryUsersRequest());

  return integrationAPI.users
    .query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryUsersSuccess(response.data));

      return response.data;
    })
    .catch(e => dispatch(queryUsersError(storableError(e))));
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  dispatch(fetchAvailabilityExceptionsRequest(fetchParams));

  return integrationAPI.availabilityExceptions
    .query(fetchParams, { expand: true })
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response.data);
      dispatch(fetchAvailabilityExceptionsSuccess(availabilityExceptions));
      return availabilityExceptions;
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

  const today = new Date();
  const start = resetToStartOfDay(today, tz, 0);
  // Query range: today + 364 days
  const exceptionRange = 364;
  const end = resetToStartOfDay(today, tz, exceptionRange);

  // NOTE: in this template, we don't expect more than 100 exceptions.
  // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
  const fetchExceptionsParams = {
    listingId: params.id,
    start,
    end,
  };

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return Promise.all([
      dispatch(showListing(listingId, true)),
      dispatch(queryUsers()),
      dispatch(requestFetchAvailabilityExceptions(fetchExceptionsParams)),
    ]);
  }

  return Promise.all([
    dispatch(showListing(listingId)),
    dispatch(queryExperiences()),
    dispatch(fetchReviews(listingId)),
    dispatch(queryUsers()),
    dispatch(requestFetchAvailabilityExceptions(fetchExceptionsParams)),
  ]);
};
