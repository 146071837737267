import React, { Component } from 'react';
import { string, number } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import truncate from 'lodash/truncate';
import { InlineTextButton, IconArrowHead } from '../../components';
import classNames from 'classnames';

import css from './ExpandableBio.module.css';

const BIO_COLLAPSED_LENGTH = 170;

const truncated = (s, length = BIO_COLLAPSED_LENGTH) => {
  return truncate(s, {
    length,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { rootClassName, className, bio, maxLength } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const truncatedBio = truncated(bio, maxLength);

    const handleShowMoreClick = () => {
      this.props.onReadMore ? this.props.onReadMore() : this.setState({ expand: true });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="UserCard.showFullBioLink" />
        <IconArrowHead className={css.arrowIcon} direction="right" />
      </InlineTextButton>
    );
    return (
      <div className={classes}>
        <p className={css.expandableText}>{expand ? bio : truncatedBio}</p>
        {bio !== truncatedBio && !expand ? showMore : null}
      </div>
    );
  }
}

ExpandableBio.defaultProps = {
  rootClassName: null,
  className: null,
  maxLength: BIO_COLLAPSED_LENGTH,
};

ExpandableBio.propTypes = {
  rootClassName: string,
  className: string,
  bio: string.isRequired,
  maxLength: number,
};

export default ExpandableBio;
