import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckMark.module.css';

const SIZE_SMALL = 'small';
const SIZE_BIG = 'big';

const IconCheckmark = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);
  if (size === SIZE_SMALL) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 78.369 78.369"
        style={{
          enableBackground: 'new 0 0 16 16',
        }}
        xmlSpace="preserve"
      >
        <path
          d="M78.049 19.015 29.458 67.606a1.094 1.094 0 0 1-1.548 0L.32 40.015a1.094 1.094 0 0 1 0-1.547l6.704-6.704a1.095 1.095 0 0 1 1.548 0l20.113 20.112 41.113-41.113a1.095 1.095 0 0 1 1.548 0l6.703 6.704a1.094 1.094 0 0 1 0 1.548z"
          data-original="#fff"
          xmlns="http://www.w3.org/2000/svg"
        />
      </svg>
    );
  } else if (size === SIZE_BIG) {
    return (
      <svg className={classes} strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.6 1.2c-.4-.3-1-.2-1.3.2L7.8 19l-5.2-5c-.4-.4-1-.4-1.3 0-.4.3-.4.8 0 1l6 5.6.6.2s.2 0 .4-.4l14.3-18c.3-.5.2-1-.2-1" />
      </svg>
    );
  }
};

IconCheckmark.defaultProps = {
  rootClassName: null,
  className: null,
  size: 'big',
};

const { string } = PropTypes;

IconCheckmark.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconCheckmark;
