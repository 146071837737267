import React from 'react';

const BellIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 13.501h2M11.443 4.942a4.443 4.443 0 00-8.886 0v4.413c0 .442-.175.865-.488 1.178-.312.312-.627.488-1.069.488h12c-.442 0-.757-.176-1.07-.488a1.666 1.666 0 01-.487-1.178V4.942z"
      ></path>
    </svg>
  );
};

export default BellIcon;
