import { Children } from 'react';

// First valid slidesToShow index
const SLIDES_TO_SHOW_INDEX = 1;

// Breakpoints
export const MAX_MOBILE_SCREEN_WIDTH = 768;
export const MAX_TABLET_SCREEN_WIDTH = 1128;

// slidesToShow
export const SLIDES_TO_SHOW = 2.5;
export const SLIDES_TO_SHOW_TABLET = 2;
export const SLIDES_TO_SHOW_DESKTOP = 3;
// slidesToScroll
export const SLIDES_TO_SCROLL = 1;
export const SLIDES_TO_SCROLL_TABLET = 2;
export const SLIDES_TO_SCROLL_DESKTOP = 3;
// Max slidesToShow
export const MAX_MOBILE_SLIDES_TO_SHOW = 3;
export const MAX_TABLET_SLIDES_TO_SHOW = 5;
export const MAX_DESKTOP_SLIDES_TO_SHOW = 5;

/**
 * Helper function to calculate the total slider items
 * length.
 *
 * @param {node} children
 *
 * @return {number}
 */
export const totalSlidesFn = children => {
  const sliderChildren = Children.map(children, child => child);
  const totalSlides = sliderChildren ? sliderChildren.length : 0;

  return totalSlides;
};

/**
 * Helper function to maintain slider settings based
 * on current window size/viewport.
 *
 * @param {object} slidesToShow
 * @param {object} slidesToScroll
 * @param {object} viewport
 * @param {string} sliderId
 *
 * @return {object}
 */
export const responsiveSettingsFn = (slidesToShow, slidesToScroll, viewport, sliderId) => {
  let sliderSlidesToShow = slidesToShow.desktop || SLIDES_TO_SHOW_DESKTOP;
  let sliderSlidesToScroll = slidesToScroll.desktop || SLIDES_TO_SCROLL_DESKTOP;

  // DESKTOP UI WARNING
  if (slidesToShow?.desktop > MAX_DESKTOP_SLIDES_TO_SHOW) {
    console.warning(
      `${sliderId} slidesToShow should show less then ${MAX_DESKTOP_SLIDES_TO_SHOW +
        1} items on desktop.`
    );
  }

  if (viewport.width < MAX_MOBILE_SCREEN_WIDTH) {
    sliderSlidesToShow = slidesToShow.mobile || SLIDES_TO_SHOW;
    sliderSlidesToScroll = slidesToScroll.mobile || SLIDES_TO_SCROLL;

    // MOBILE UI WARNING
    if (slidesToShow?.mobile > MAX_MOBILE_SLIDES_TO_SHOW) {
      console.warning(
        `${sliderId} slidesToShow should show less then ${MAX_MOBILE_SLIDES_TO_SHOW +
          1} items on mobile.`
      );
    }
  } else if (viewport.width < MAX_TABLET_SCREEN_WIDTH) {
    sliderSlidesToShow = slidesToShow.tablet || SLIDES_TO_SHOW_TABLET;
    sliderSlidesToScroll = slidesToScroll.tablet || SLIDES_TO_SCROLL_TABLET;

    // TABLET UI WARNING
    if (slidesToShow.tablet > MAX_TABLET_SLIDES_TO_SHOW) {
      throw new Error(
        `${sliderId} slidesToShow must show less then ${MAX_TABLET_SLIDES_TO_SHOW +
          1} items on tablet.`
      );
    }
  }

  return {
    slidesToShow: sliderSlidesToShow,
    slidesToScroll: sliderSlidesToScroll,
  };
};

/**
 * Helper function to calculate the slider items
 * maxWidth based on the slidesToShow and viewport.
 *
 * @param {number} slidesToShow
 * @param {number} totalSlides
 * @param {object} viewport
 *
 * @return {string} maxWidth property
 */
export const slideMaxWidthFn = (slidesToShow, totalSlides, viewport) => {
  if (slidesToShow > SLIDES_TO_SHOW_INDEX) {
    if (viewport.width < MAX_MOBILE_SCREEN_WIDTH) {
      // Mobile items maxWidth
      return `calc((100vw - 16px) / ${slidesToShow})`; // width - margin / number of columns
    } else if (viewport.width < MAX_TABLET_SCREEN_WIDTH) {
      // Tablet items maxWidth
      return `calc((100% - (${slidesToShow * 16}px + 24px)) / ${slidesToShow})`; // width - number of columns + 1 * margin / number of columns
    } else {
      // Desktop items maxWidth
      return `calc((100% - ${(slidesToShow - 1) * 16}px) / ${slidesToShow})`; // width - number of columns + 1 * margin / number of columns
    }
  } else {
    // Render if slidesToShow = 1
    return `calc(100% - 48px)`;
  }
};
