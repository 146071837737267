import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, IconFavorite, IconSpinner } from '../../components';
import classNames from 'classnames';

import css from './FollowUser.module.css';

const FollowUser = props => {
  const {
    rootClassName,
    className,
    iconClassName,
    currentUser,
    followUserId,
    followMessageId,
    unfollowMessageId,
    onFollowUser,
    onUnfollowUser,
    inProgress,
    error,
  } = props;

  // Hide the button if there's was an error
  // following or unfollowing user
  if (error) {
    return null;
  }

  const followingIds = currentUser?.attributes?.profile?.publicData?.followingIds;
  const isFollowing = followingIds?.includes(followUserId);

  // Handle clicking on the follow button that will
  // either follow or unfollow the user
  const handleFollowClick = () => {
    if (isFollowing) {
      onUnfollowUser(followUserId);
    } else {
      onFollowUser(followUserId);
    }
  };

  // Follow message id (e.g follow and unfollow)
  const followUserMessageId = isFollowing
    ? unfollowMessageId || 'FollowHost.following'
    : followMessageId || 'FollowHost.follow';

  const classes = classNames(rootClassName || css.root, className);
  const iconClasses = classNames(css.icon, iconClassName, {
    [css.followingIcon]: isFollowing,
  });

  const followDisabled = inProgress || error;

  return (
    <InlineTextButton className={classes} onClick={handleFollowClick} disabled={followDisabled}>
      {inProgress ? (
        <IconSpinner className={css.spinner} />
      ) : (
        <IconFavorite className={iconClasses} />
      )}
      <FormattedMessage id={followUserMessageId} />
    </InlineTextButton>
  );
};

FollowUser.defaultProps = {
  rootClassName: null,
  className: null,
  iconClassName: null,
  currentUser: null,

  // Follow user
  followUserId: null,
  followMessageId: null,
  unfollowMessageId: null,
  onFollowUser: null,
  onUnfollowUser: null,
  inProgress: false,
  error: null,
};

const { string, func, bool } = PropTypes;

FollowUser.propTypes = {
  rootClassName: string,
  className: string,
  iconClassName: string,
  currentUser: propTypes.currentUser,

  // Follow user
  followUserId: string,
  followMessageId: string,
  unfollowMessageId: string,
  onFollowUser: func.isRequired,
  onUnfollowUser: func.isRequired,
  inProgress: bool.isRequired,
  error: propTypes.error,
};

export default FollowUser;
