import React from 'react';
import { AddToCalendar } from '../../components';
import config from '../../config';

import css from './TransactionPanel.module.css';

const PanelHeading = props => {
  const { intl, isEnquiryState, listing, timeslot, booking, bookingTitle } = props;

  // AddToCalendar related
  const showAddToCalendarButton = !isEnquiryState && booking;
  const bookingData = {
    title: listing.attributes.title || '',
    description: listing.attributes.description || '',
    location: timeslot?.meeting?.roomUrl || '',
    start: booking?.attributes?.start,
    end: booking?.attributes?.end,
  };

  return (
    <div className={css.panelHeading}>
      <h1 className={css.panelHeadingTitle}>{bookingTitle}</h1>
      {showAddToCalendarButton ? (
        <AddToCalendar
          bookingLabel={intl.formatMessage({ id: 'TransactionPanel.addToCalendar' })}
          bookingData={bookingData}
        />
      ) : null}
    </div>
  );
};

export default PanelHeading;
