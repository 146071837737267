import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSearch } from '../../components';
import classNames from 'classnames';

import BellIcon from './BellIcon';
import HeartIcon from './HeartIcon';
import StreamIcon from '../DashboardWrapper/Icons/StreamIcon';
import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.title" />
      </div>
      <p className={css.subTitle}>
        <FormattedMessage id="SectionHowItWorks.subTitle" />
      </p>

      <div className={css.steps}>
        <div className={css.step}>
          <IconSearch className={css.stepIcon} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <StreamIcon className={css.stepIcon} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <BellIcon className={css.stepIcon} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>

        <div className={css.step}>
          <HeartIcon className={classNames(css.stepIcon, css.heartIcon)} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part4Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part4Text" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
