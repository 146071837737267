import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { IconArrow } from '../../components';

import css from './OrganizationSettingsPage.module.css';

const RedirectBanner = props => {
  const { history, location } = props;
  const { showName } = parse(location.search);

  const locationFrom = location?.state?.from;
  const showRedirectBanner = locationFrom && showName;

  return showRedirectBanner ? (
    <div className={css.redirectBanner} onClick={() => history.push(locationFrom)}>
      <IconArrow />
      <FormattedMessage id="OrganizationSettingsPage.redirect" values={{ showName }} />
    </div>
  ) : null;
};

export default RedirectBanner;
