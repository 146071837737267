import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';

export const QUERY_EXPERIENCES_REQUEST = 'app/QuickRepliesPage/QUERY_EXPERIENCES_REQUEST';
export const QUERY_EXPERIENCES_SUCCESS = 'app/QuickRepliesPage/QUERY_EXPERIENCES_SUCCESS';
export const QUERY_EXPERIENCES_ERROR = 'app/QuickRepliesPage/QUERY_EXPERIENCES_ERROR';

// ================ Reducer ================ //

const initialState = {
  experienceIds: [],
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
};

const resultIds = data => data.data.map(d => d.id);

export default function scheduleMessagesReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryExperiencesInProgress: true,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_SUCCESS:
      return {
        ...state,
        experienceIds: resultIds(payload.data),
        queryExperiencesInProgress: false,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_ERROR:
      return {
        ...state,
        experienceIds: [],
        queryExperiencesInProgress: false,
        queryExperiencesError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryExperiencesRequest = () => ({
  type: QUERY_EXPERIENCES_REQUEST,
});
export const queryExperiencesSuccess = response => ({
  type: QUERY_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});
export const queryExperiencesError = e => ({
  type: QUERY_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const queryExperiences = () => (dispatch, getState, sdk) => {
  dispatch(queryExperiencesRequest());

  return sdk.currentUser.show().then(response => {
    const currentUser = response.data.data;

    return sdk.listings
      .query({
        authorId: currentUser.id,
        pub_type: config.listingTypes['experience'],
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(queryExperiencesSuccess(response));
        return response;
      })
      .catch(e => dispatch(queryExperiencesError(storableError(e))));
  });
};

export const loadData = () => (dispatch, getState, sdk) => {
  return dispatch(queryExperiences());
};
