import React from 'react';
import { arrayOf, array, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingBasicInfoPanel,
  EditListingTicketsPanel,
  EditListingContentPanel,
} from '../../components';

import css from './EditListingWizard.module.css';

export const BASIC = 'basic';
export const CONTENT = 'content';
export const TICKETS = 'tickets';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [BASIC, CONTENT, TICKETS];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

const pathParamsToPreviousTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex(s => s === tab) - 1;
  const prevTab =
    prevTabIndex < marketplaceTabs.length ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];
  return { ...params, tab: prevTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const redirectToPreviousTab = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    id: listingId,
  };

  const routes = routeConfiguration();
  // Redirect to previous tab
  const previousPathParams = pathParamsToPreviousTab(currentPathParams, tab, marketplaceTabs);

  const to = createResourceLocatorString('EditListingPage', routes, previousPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onVideosUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onRemoveVideo,
    onChange,
    onManageDisableScrolling,
    onSetCoverPhoto,
    onUpdateImages,
    setCoverPhotoInProgress,
    setCoverPhotoError,
    updatedTab,
    updateInProgress,
    videos,
    currentUser,
    showListings,
    intl,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      location,
      history,
      listing,
      currentUser,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      onRedirectToPreviousTab: () =>
        redirectToPreviousTab(currentListing.id.uuid, params, tab, marketplaceTabs, history),
      // newListingPublished is flag for the last wizard tab
      ready: newListingPublished,
      disabled: false,
    };
  };

  switch (tab) {
    case BASIC: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewBasicInfo'
        : 'EditListingWizard.saveEditBasicInfo';
      return (
        <EditListingBasicInfoPanel
          {...panelProps(BASIC)}
          showListings={showListings}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case CONTENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewContent'
        : 'EditListingWizard.saveEditContent';

      return (
        <EditListingContentPanel
          {...panelProps(CONTENT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onUpdateImages={onUpdateImages}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onVideosUpload={onVideosUpload}
          onRemoveVideo={onRemoveVideo}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          onSetCoverPhoto={onSetCoverPhoto}
          setCoverPhotoInProgress={setCoverPhotoInProgress}
          setCoverPhotoError={setCoverPhotoError}
          videos={videos}
        />
      );
    }
    case TICKETS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTickets'
        : 'EditListingWizard.saveEditTickets';
      return (
        <EditListingTicketsPanel
          {...panelProps(TICKETS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  setCoverPhotoInProgress: false,
  setCoverPhotoError: null,
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,

  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,

  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  onSetCoverPhoto: func.isRequired,
  onUpdateImages: func.isRequired,
  setCoverPhotoInProgress: bool.isRequired,
  setCoverPhotoError: propTypes.error,
  showListings: arrayOf(propTypes.listing),

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
